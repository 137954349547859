import type { InputHTMLAttributes, ReactNode } from 'react';
import { forwardRef } from 'react';

import { ScreenReaderOnly } from '../../ScreenReaderOnly/ScreenReaderOnly';

import { HelperText } from './HelperText';
import { AdornmentContainer, LabelElement, TextFieldTopLevelElement, TextInputPrimitive, VisibleLabel } from './styles';

import type { RequiredLabelProp, AutoCompleteOptions } from '../types';

const EMPTY_SPACE = ' ';

export interface TextFieldProps
  // name is omitted from InputHTMLAttributes<HTMLInputElement> and then added to the interface to make it required.
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'children' | 'name' | 'placeholder' | 'style' | 'type'>,
    RequiredLabelProp {
  name: string;

  /**
   * We can easily use a small subset of [the possible types from a typical HTML input element](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#%3Cinput%3E_types).
   * If the type is not valid, it belongs to a different component or we havent tested that it works with this
   * component. If you do add to the possible types, please also check the Attributes table on the MDN page for "input"
   * to ensure the typings are inclusive.
   *
   * To use the "password" type, use the `PasswordField` component instead.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes
   */
  type?: 'email' | 'number' | 'tel' | 'text' | 'url';

  /**
   * Autocomplete helps to fill an input with device-remembered values. See MDN's documentation on the [attribute and
   * its values](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#Values).
   */
  autoComplete?: AutoCompleteOptions;

  adornment?: {
    left?: ReactNode;
    right?: ReactNode;
    size?: 'sm' | 'md';
    style?: {
      bottom?: string;
    };
  };

  /**
   * The description appears below the input's bottom border. It won't be rendered if there's a value for the `error`
   * prop. This prop is intentionally a `string` type. You cannot and should not pass anything beside a string because
   * it won't be vocalizable by screen readers / assistive technology.
   */
  description?:
    | string
    | {
        value: string;
        isHidden?: boolean;
      };

  /**
   * The error message appears below the input's bottom border. Any error messaage present should be about an error
   * related specifically to the input. You should NOT place form-level errors here. In most cases, you should
   * not pass anything beside a string because it won't be vocalizable by screen readers / assistive technology.
   */
  error?: string | ReactNode;

  'data-testid'?: string;

  inputBottomPadding?: string;

  labelSize?: 'sm' | 'md';
}

/**
 * Our TextField component (known as a text input to most web developers) has a similar UX to the [Material UI text
 * field component](https://material-ui.com/components/text-fields/). It can potentially suffer from [a11y
 * issues and poor UX](https://css-tricks.com/html-inputs-and-labels-a-love-story/#aa-component-library-material) if
 * implemented poorly. We've attempted to ensure this input is accessible and easy to use.
 *
 * While this component is an abstraction around the potential combination of a label, input, description, error, and
 * input adornments (left or right), a `TextInputPrimitive` is also exported for cases where the assumptions within
 * fail your use case. If that is the case, please open a discussion in
 * [#design-system](https://capsulerx.slack.com/archives/C030NMA4HDF)! on Slack. Also, while the documentation for that
 * separate export (`TextInputPrimitive`) isn’t listed on Storybook, it is applied as directly paired JSDocs, so pay
 * attention to the intellisense on import!
 */
export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      adornment,
      autoComplete = 'off',
      description,
      disabled = false,
      error,
      id,
      name,
      readOnly = false,
      type = 'text',
      label,
      'aria-describedby': ariaDescribedBy,
      inputBottomPadding,
      labelSize,
      ...restOfProps
    },
    forwardedRef
  ) => {
    const identifier = id || name;
    const descriptionIdentifier = `${identifier}-description`;
    const errorIdentifier = `${identifier}-error`;
    const hasError = !!error;

    /**
     * This next group of code is just to concatenate the `aria-describedby` prop with all of the
     * proper identifiers given the props passed to the component.
     *
     * It could be just `${descriptionIdentifier}`, but it could also be
     * `${errorIdentifier}` or
     * `${descriptionIdentifier} ${ariaDescribedBy}` or
     * `${descriptionIdentifier} ${errorIdentifier}` or
     * `${descriptionIdentifier} ${errorIdentifier} ${ariaDescribedBy}`
     */
    let describedBy = '';
    if (description) describedBy = descriptionIdentifier;
    if (hasError) describedBy = describedBy.concat(` ${errorIdentifier}`).trim();
    if (ariaDescribedBy) describedBy = describedBy.concat(` ${ariaDescribedBy}`).trim();

    return (
      <TextFieldTopLevelElement
        inputBottomPadding={inputBottomPadding}
        adornmentSize={adornment?.size}
        data-adorned={adornment ? (adornment.left ? 'left' : 'right') : undefined}
      >
        {adornment?.left && <AdornmentContainer data-side="left">{adornment.left}</AdornmentContainer>}

        <TextInputPrimitive
          as="input"
          ref={forwardedRef}
          autoComplete={autoComplete}
          disabled={disabled || readOnly}
          id={identifier}
          readOnly={readOnly}
          name={name}
          type={type}
          aria-invalid={hasError}
          aria-describedby={describedBy}
          aria-errormessage={hasError ? errorIdentifier : undefined}
          /**
           * We use an empty space as the value for placeholder to ensure it always has "value". This allows us to
           * leverage the :placeholder-shown pseudo selector because :blank isn't supported in most browsers.
           *
           * @see https://developer.mozilla.org/en-US/docs/Web/CSS/:placeholder-shown
           * @see https://developer.mozilla.org/en-US/docs/Web/CSS/Pseudo-classes#input_pseudo-classes
           */
          placeholder={EMPTY_SPACE}
          {...restOfProps}
        />

        <LabelElement htmlFor={identifier}>
          {label.isHidden ? (
            <ScreenReaderOnly as="span">{label.content}</ScreenReaderOnly>
          ) : (
            <VisibleLabel as="span" size={labelSize}>
              {label.content}
            </VisibleLabel>
          )}
        </LabelElement>

        {adornment?.right && (
          <AdornmentContainer data-side="right" bottom={adornment?.style?.bottom}>
            {adornment.right}
          </AdornmentContainer>
        )}

        <HelperText
          description={{
            identifier: descriptionIdentifier,
            value: typeof description === 'string' ? description : description?.value,
            isHidden: typeof description === 'string' ? false : description?.isHidden ?? false,
          }}
          error={{
            identifier: errorIdentifier,
            value: error,
          }}
        />
      </TextFieldTopLevelElement>
    );
  }
);
