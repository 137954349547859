import type { FC, FieldsetHTMLAttributes, InputHTMLAttributes, Ref } from 'react';
import { forwardRef } from 'react';

import styled, { css } from 'styled-components';

import { ScreenReaderOnly, screenReaderOnlyCSS } from '../../../ScreenReaderOnly/ScreenReaderOnly';
import { Typography } from '../../../Typography/Typography';
import { truncatedEllipsizedTextCSS } from '../../../utilityCSS';
import { Checkmark } from '../CheckboxTick/CheckboxTick';

import type { RequiredLabelProp } from '../../types';

export enum CheckboxButtonGroupVariant {
  SingleColumn = 'singleColumn',
  SingleColumnCard = 'singleColumnCard',
  MultiColumnFixed = 'multiColumnFixed',
  MultiColumnHug = 'multiColumnHug',
}

export interface CheckboxButtonGroupOption
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'ref' | 'value' | 'name' | 'style'> {
  /** value maps to label */
  name?: never;

  label: string;

  /**
   * Note: Each option is rendered as an input with `type="checkbox"`. The `value` aspect of an option is also used
   * as the input's `name`.
   */
  value: string;

  /**
   * This is a description that will be rendered below the label.
   */
  description?: string;

  innerRef?: Ref<HTMLInputElement>;

  /**
   * We don't render any error messages with a `CheckbotButtonGroup` option because we presume that a
   * form-level error is being rendered. We do mark the individual inputs with `aria-invalid=true` and
   * style the relevant options with red; however, the accessible error messaging is considered a
   * "higher up" concern.
   */
  error?: string;
}

export interface CheckboxButtonGroupProps
  extends Omit<FieldsetHTMLAttributes<HTMLFieldSetElement>, 'children' | 'style'>,
    RequiredLabelProp {
  /**
   * This component is a fieldset of checkboxes. The `value` aspect of each option passed to the `options` prop will
   * be used as each input's name.
   */
  name?: never;

  /**
   * Each option is a grouping of a labelled input element. A `label` and `value` is required for each option; however,
   * you may also pass most props relevant to `InputHTMLAttributes<HTMLInputElement>` (see source code for details).
   * Please note: The ability to control this prop is disabled in Storybook for simplicity.
   */
  options: CheckboxButtonGroupOption[];

  /** This prop determines how the selector is laid out. */
  variant?: CheckboxButtonGroupVariant;

  'data-testid'?: string;
}

const CheckboxButtonGroupContainer = styled(Typography).attrs({ variant: 'body' })`
  /** Reset */
  margin: 0;
  padding: 0;
  border: none;
  outline: none;

  /** Actual styles */
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.s3};
  width: 100%;
`;

const VisibleGroupLabel = styled(Typography).attrs({ as: 'span', variant: 'body' })`
  color: ${({ theme }) => theme.colors.capsuleBlue90};
`;

const OptionsContainer = styled.div<{
  variant: CheckboxButtonGroupProps['variant'];
}>`
  display: flex;
  // Smaller gap between options in the Card button variant
  gap: ${({ theme, variant }) =>
    variant === CheckboxButtonGroupVariant.SingleColumnCard ? theme.space.s1 : theme.space.s4};
  flex-direction: ${({ variant }) => (variant === CheckboxButtonGroupVariant.SingleColumn ? 'column' : 'row')};
  flex-wrap: ${({ variant }) => (variant === CheckboxButtonGroupVariant.SingleColumn ? 'nowrap' : 'wrap')};

  ${({ variant }) =>
    variant == CheckboxButtonGroupVariant.SingleColumn &&
    `
  & > * label {
    white-space: pre-wrap;
  }
`}

  ${({ variant }) =>
    variant !== CheckboxButtonGroupVariant.MultiColumnHug &&
    `
  & > *,
  & > * label {
    width: 100%;
  }
`}

  /** 2 columns of equal width. */
  ${({ theme, variant }) =>
    variant === CheckboxButtonGroupVariant.MultiColumnFixed &&
    `display: grid;
     grid-template-columns: 1fr 1fr;
     grid-template-rows: auto;
     gap: ${theme.space.s4};
`}

  /** 3 columns of equal width. */
  ${({ theme }) => theme.mediaQueries.small} {
    ${({ variant }) =>
      variant === CheckboxButtonGroupVariant.MultiColumnFixed &&
      `
      grid-template-columns: 1fr 1fr 1fr;
    `}
  }

  /** 4 columns of equal width */
  ${({ theme }) => theme.mediaQueries.large} {
    ${({ variant }) =>
      variant === CheckboxButtonGroupVariant.MultiColumnFixed &&
      `
      grid-template-columns: 1fr 1fr 1fr 1fr;
    `}
  }
`;

const DefaultOption = styled(Typography).attrs({ as: 'div', variant: 'body' })`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  overflow: hidden;

  @media (hover: hover) {
    &:hover input:checked + label {
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.capsuleGreen80};
    }

    &[aria-invalid='true']:hover input:not(:checked) + label {
      cursor: pointer;
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.capsuleRed60};
    }

    &[aria-invalid='false']:hover input:not(:checked) + label {
      cursor: pointer;
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.capsuleGreen60};
    }
  }
`;

const InvisibleInputElement = styled.input`
  /** Reset */
  appearance: none;
  margin: 0;
  outline: none;
  ${screenReaderOnlyCSS}

  &:focus {
    outline: none;
  }

  & + label {
    transition: all ${({ theme }) => theme.animation.typical};
  }

  &:checked + label {
    background-color: ${({ theme }) => theme.colors.capsuleGreen60};
    color: ${({ theme }) => theme.colors.white};
  }

  &:checked:focus-visible + label {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.white};
    outline: 2px solid ${({ theme }) => theme.colors.white};
    outline-offset: ${({ theme }) => `-${theme.space.s1}`};
  }

  &:not(:checked) + label {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.capsuleGreen60};
  }

  &:not(:checked):focus-visible + label {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.capsuleGreen60};
    outline: 2px solid ${({ theme }) => theme.colors.capsuleGreen60};
    outline-offset: ${({ theme }) => `-${theme.space.s1}`};
  }

  &[aria-invalid='true'] + label {
    border-color: ${({ theme }) => theme.colors.capsuleRed60};
    color: ${({ theme }) => theme.colors.capsuleRed60};
  }

  &[aria-invalid='true']:focus-visible + label {
    outline-color: ${({ theme }) => theme.colors.capsuleRed60};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.capsuleRed60};
  }
`;

const ElementWithButtonStyling = styled(Typography).attrs({ variant: 'heading4' })`
  border-radius: ${({ theme }) => theme.radii.r100};
  border: 1px solid ${({ theme }) => theme.colors.capsuleGreen60};
  padding: ${({ theme }) => theme.space.s3};
  margin: 1px; /** we need 1px of margin on each option so that the box-shadow doesn't get cropped */
  user-select: none;
  transition: background-color ${({ theme }) => theme.animation.typical},
    box-shadow ${({ theme }) => theme.animation.typical}, color ${({ theme }) => theme.animation.typical};

  /** Ideally the options don't ellipsize, but we need to be prepared! */
  ${truncatedEllipsizedTextCSS}
`;

const CardOption = styled(Typography).attrs({ as: 'div', variant: 'body' })`
  display: flex;
  cursor: pointer;
  overflow: hidden;
  transition: all ${({ theme }) => theme.animation.typical};

  &[aria-invalid='true'] input:not(:checked) + label {
    cursor: pointer;
    outline: 1px solid ${({ theme }) => theme.colors.capsuleRed60};
  }

  @media (hover: hover) {
    &:hover input:checked + label {
      cursor: pointer;
      outline: 2px solid ${({ theme }) => theme.colors.capsuleGreen60};
    }

    &[aria-invalid='true']:hover input:not(:checked) + label {
      cursor: pointer;
      outline: 2px solid ${({ theme }) => theme.colors.capsuleRed60};
    }

    &[aria-invalid='false']:hover input:not(:checked) + label {
      cursor: pointer;
      outline: 1px solid ${({ theme }) => theme.colors.capsuleGreen10};
    }
  }
`;

const CardInvisibleInputElement = styled.input`
  /** Reset */
  appearance: none;
  margin: 0;
  outline: none;
  ${screenReaderOnlyCSS}
  &:focus + label {
    outline: 1px solid ${({ theme }) => theme.colors.capsuleGreen50};
  }

  &:checked + label {
    background-color: ${({ theme }) => theme.colors.capsuleGreen10};
    outline: 1px solid ${({ theme }) => theme.colors.capsuleGreen60};
  }

  &:not(:checked) + label {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  text-align: left;
  text-transform: none;
  text-wrap: wrap;
  letter-spacing: 0;
`;

const ElementWithCardAndCheckboxStyling = styled.div`
  display: flex;
  flex-direction: row;

  border-radius: ${({ theme }) => theme.radii.r100};
  user-select: none;

  /** Ideally the options don't ellipsize, but we need to be prepared! */
  ${truncatedEllipsizedTextCSS}

  padding: ${({ theme }) => theme.space.s4};
  /** margin here so that the box shadow can be seen */
  margin: 2px 4px 6px 2px;
  box-shadow: 0px 2px 6px 0px rgba(153, 191, 188, 0.24), 0px 0px 1px 0px rgba(110, 120, 129, 0.16);
`;

const BlueBodyText = styled(Typography)`
  color: ${({ theme }) => theme.colors.capsuleBlue30};
  font-weight: ${({ theme }) => theme.fontWeights.book};
  margin: ${({ theme }) => theme.space.s1} 0;
`;

const CheckmarkContainer = styled.div<{ checked: boolean; hasError: boolean }>`
  margin: 0;
  appearance: none;
  margin-left: auto;
  background-color: ${({ theme }) => theme.colors.transparent};
  border: 1px solid ${({ theme, hasError }) => (hasError ? theme.colors.capsuleRed80 : theme.colors.capsuleBlue90)};
  border-radius: ${({ theme }) => theme.radii.r100};
  align-self: center;

  /* Dimensions */
  width: 1.8rem;
  height: 1.8rem;

  & svg {
    width: 1.8rem;
    height: 1.8rem;
    position: relative;
    left: 0px;
    top: 0px;
    margin-left: -1px;
    margin-top -1px;
  }

  & svg > path {
    fill: ${({ checked, theme }) => (checked ? theme.colors.capsuleBlue90 : 'none')};
  }
`;

interface OptionElementProps {
  option: CheckboxButtonGroupOption;
  variant?: CheckboxButtonGroupVariant;
}

export const OptionElement: FC<OptionElementProps> = ({ option, variant }) => {
  const identifier = option.value;
  const hasError = !!option.error;
  switch (variant) {
    case CheckboxButtonGroupVariant.SingleColumnCard:
      return (
        <CardOption key={identifier} aria-invalid={hasError}>
          <CardInvisibleInputElement
            {...option}
            ref={option.innerRef}
            id={identifier}
            name={identifier}
            aria-invalid={hasError}
            type="checkbox"
          />
          <ElementWithCardAndCheckboxStyling as="label" htmlFor={option.value} title={option.label}>
            <TextColumn>
              <Typography
                variant={'heading3'}
                css={css`
                  margin-top: ${({ theme }) => theme.space.s1};
                `}
              >
                {option.label}
              </Typography>
              <BlueBodyText variant={'body'}>{option.description}</BlueBodyText>
            </TextColumn>
            <CheckmarkContainer checked={option?.checked ? option.checked : false} hasError={hasError}>
              <Checkmark />
            </CheckmarkContainer>
          </ElementWithCardAndCheckboxStyling>
        </CardOption>
      );
    default:
      return (
        <DefaultOption key={identifier} aria-invalid={hasError}>
          <InvisibleInputElement
            {...option}
            ref={option.innerRef}
            id={identifier}
            name={identifier}
            aria-invalid={hasError}
            type="checkbox"
          />
          <ElementWithButtonStyling as="label" htmlFor={option.value} title={option.label}>
            {option.label}
          </ElementWithButtonStyling>
        </DefaultOption>
      );
  }
};

/**
 * There are multiple checkbox inputs. This is the "button group" checkbox. It's named `CheckboxButtonGroup` to help
 * import intellisense provide better discoverability of all components with the `CheckboxX` naming pattern.
 * `CheckboxButtonGroup` - as the naming suggests - is only meant to be leveraged in group contexts. There exists no
 * `CheckboxButton` component to use in isolation.
 *
 * Notes:
 *  - There is no accepted way for rendering an error on any one individual checkbox.
 *
 * [Figma Board](https://www.figma.com/file/GqS7heDJrx0DK27EU2Vh6u/Design-System-Documentation?node-id=4%3A1931)
 */
export const CheckboxButtonGroup = forwardRef<HTMLFieldSetElement, CheckboxButtonGroupProps>(
  ({ className, label, options, variant, ...props }, ref) => {
    return (
      <CheckboxButtonGroupContainer as="fieldset" {...props} className={className} ref={ref}>
        {label.isHidden ? (
          <ScreenReaderOnly as="legend">{label.content}</ScreenReaderOnly>
        ) : (
          <VisibleGroupLabel as="legend">{label.content}</VisibleGroupLabel>
        )}

        <OptionsContainer variant={variant}>
          {options.map(option => {
            return <OptionElement option={option} variant={variant} />;
          })}
        </OptionsContainer>
      </CheckboxButtonGroupContainer>
    );
  }
);
