import { forwardRef } from 'react';

import styled, { keyframes } from 'styled-components';

import { ScreenReaderOnly } from '../ScreenReaderOnly/ScreenReaderOnly';

/**
 * Kyle: I have no clue WHY the math on this works; however, the goal was to make the path always
 * remain visible regardless of how long it is. There is no frame where the path does not at
 * least _exist_; however, it does get to it's smallest possible length and then gets to about
 * 270 degrees of its circumference before receding again.
 *
 * We are attempting to somewhat emulate this animation that Google uses:
 * @see https://codepen.io/jczimm/pen/vEBpoL
 */
const getAnimatedCirlceDash = ({ size }: { size: number }) => keyframes`
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: -0px;
  }

  50% {
    stroke-dasharray: 50px, 200px;
    stroke-dashoffset: -${size}px;
  }

  100% {
    stroke-dasharray: 90px, 200px;
    stroke-dashoffset: -${size * 2.73}px;
  }
`;

const pulseAnimated = keyframes`
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const circularRotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const Container = styled.div<{ size: number }>`
  display: inline-block;
  line-height: 1;
  width: ${props => props.size / 10}rem;
  height: ${props => props.size / 10}rem;
  color: currentColor;

  /* If the user prefers reduced motion, we do a circle with pulsing opacity instead. */
  ${({ theme }) => theme.mediaQueries.prefersReducedMotion} {
    animation: ${pulseAnimated} 1s linear infinite;

    & > svg {
      animation: none;
    }

    & > svg > circle {
      animation: none;
      stroke-dasharray: none;
      stroke-dashoffset: none;
    }
  }
`;

const SVG = styled.svg`
  animation: ${circularRotate} 1s linear infinite;
  display: block;
  color: currentColor;
`;

const Circle = styled.circle<{ size: number; thickness: number }>`
  stroke: currentColor;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: ${({ thickness }) => thickness};
  animation: ${({ size }) => getAnimatedCirlceDash({ size })} 1.4s ease-in-out infinite;
`;

export interface InlineSpinnerProps {
  className?: string;
  'data-testid'?: string;
  size?: number;
  thickness?: number;
}

export const InlineSpinner = forwardRef<HTMLDivElement, InlineSpinnerProps>(
  ({ size = 40, thickness = 3, ...props }, ref) => (
    <Container size={size} {...props} ref={ref}>
      <ScreenReaderOnly>Loading</ScreenReaderOnly>

      <SVG
        aria-hidden
        width={size}
        height={size}
        viewBox={`${size / 2} ${size / 2} ${size} ${size}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <Circle cx={size} cy={size} fill="none" r={(size - thickness) / 2} size={size} thickness={thickness} />
      </SVG>
    </Container>
  )
);
