import type { InputHTMLAttributes } from 'react';
import { forwardRef } from 'react';

import styled from 'styled-components';

import { ScreenReaderOnly } from '../../../ScreenReaderOnly/ScreenReaderOnly';
import { Typography } from '../../../Typography/Typography';

import type { RequiredLabelProp } from '../../types';

export interface CheckboxTickProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'children' | 'name' | 'style' | 'type' | 'defaultChecked'>,
    Required<Pick<InputHTMLAttributes<HTMLInputElement>, 'defaultChecked'>>,
    RequiredLabelProp {
  name: string;
  'data-testid'?: string;
}

const CheckboxTickContainer = styled.label`
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.space.s3};

  &:hover {
    cursor: pointer;
  }
`;

const StyledCheckboxTickLabel = styled(Typography).attrs({ as: 'span', variant: 'body' })`
  color: ${({ theme }) => theme.colors.capsuleBlue90};
  flex: 1;
  user-select: none;
`;

export const Checkmark = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43" fill="none" aria-hidden="true">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.065 2.065C.01 4.118.01 7.423.01 14.033v14.023c0 6.61 0 9.915 2.054 11.969 2.053 2.053 5.358 2.053 11.968 2.053h14.023c6.61 0 9.915 0 11.968-2.053 2.054-2.054 2.054-5.36 2.054-11.97V14.035c0-6.61 0-9.916-2.054-11.97C37.971.012 34.666.012 28.056.012H14.033c-6.61 0-9.915 0-11.968 2.054Zm29.98 13.621a2.337 2.337 0 1 0-3.305-3.305L17.54 23.582l-4.19-4.19a2.337 2.337 0 1 0-3.305 3.305l7.495 7.495 14.506-14.506Z"
    />
  </svg>
);

const CheckboxInputElement = styled.input`
  margin: 0;
  appearance: none;
  background-color: ${({ theme }) => theme.colors.transparent};
  border: 1px solid ${({ theme }) => theme.colors.capsuleBlue90};
  border-radius: ${({ theme }) => theme.radii.r100};
  outline: none;

  /* Dimensions */
  width: 1.8rem;
  height: 1.8rem;
  margin-top: 1px; /** just to better vertically align the checkbox with the visible label */
  & + svg {
    width: 1.8rem;
    height: 1.8rem;
    margin-top: 1px; /** just to better vertically align the checkbox with the visible label */
    position: absolute;
  }

  &:focus-visible {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.capsuleBlue90};
  }

  &:checked + svg > path {
    fill: ${({ theme }) => theme.colors.capsuleBlue90};
  }
  &:not(:checked) + svg > path {
    fill: none;
  }
`;

/**
 * There are multiple checkbox inputs. This is the "tick" checkbox. It's named `CheckboxTick` to help import
 * intellisense provide better discoverability of all components with the `CheckboxX` naming pattern. `CheckboxTick`
 * can be used by itself in a form context or as one of many within a group. If you do use `CheckboxTick` as one of
 * many within a related group, please attempt to implement them in a way that helps assistive technology make users
 * aware of the relevant grouping. There are 2 ways to accomplish this:
 * 1. Use `<fieldset>` [(MDN)](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/fieldset) and
 * `<legend>` [(MDN)](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/legend). If you are given a design with
 * no visible`<legend>` for a visibly obvious grouping, simply use `<ScreenReaderOnly as="legend">`. You can see an
 * example of leveraging `<fieldset>` and `<legend>` in the source code of `CheckboxButtonGroup`.
 * 2. Use `role="group"`
 * // TODO: Investigate role="group" vs. fieldset + legend
 *
 * Notes:
 *  - There is no accepted way for rendering an error on any one individual checkbox.
 *
 * [Figma Board](https://www.figma.com/file/GqS7heDJrx0DK27EU2Vh6u/Design-System-Documentation?node-id=4%3A1931)
 */
export const CheckboxTick = forwardRef<HTMLInputElement, CheckboxTickProps>(({ className, label, ...props }, ref) => {
  const identifier = props.id || props.name;

  return (
    <CheckboxTickContainer htmlFor={identifier} className={className}>
      <CheckboxInputElement
        {...props}
        id={identifier}
        type="checkbox"
        ref={ref}
        // defaultChecked - Don't define this prop here because sometimes checkbox inputs can exist in groups where `value` is not a boolean.
      />

      <Checkmark />

      {label.isHidden ? (
        <ScreenReaderOnly>{label.content}</ScreenReaderOnly>
      ) : (
        <StyledCheckboxTickLabel>{label.content}</StyledCheckboxTickLabel>
      )}
    </CheckboxTickContainer>
  );
});
