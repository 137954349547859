/**
 * This is a dev-only invariant function. Essentially, throw unless true (in dev). It will also act as a type assertion
 * for the TypeScript compiler.
 */
export function invariant(
  /** If this condition is not met (and it is not a prod NODE_ENV) then an error is thrown. */
  condition = false,
  /** Explain why this error is being thrown. Think about the developer who will see it. */
  reason: string | (() => string)
): asserts condition {
  if (condition) return;
  if (process.env.NODE_ENV === 'production') return;

  // When not in production we allow the message to pass through
  const providedReason = typeof reason === 'function' ? reason() : reason;
  const errorMessage = `Invariant failed: ${providedReason}`;
  window.alert(errorMessage);
  throw new Error(errorMessage);
}
