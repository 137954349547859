import { css } from 'styled-components';

/**
 * CSS to truncate text with an ellipsis when the content overflows.
 * @see https://css-tricks.com/snippets/css/truncate-string-with-ellipsis/
 */
export const truncatedEllipsizedTextCSS = css`
  text-overflow: ellipsis;
  white-space: nowrap;

  /**
   * Eventually, it'll be better to use "clip" as the value for overflow to prevent scroll bars in certain scenarios;
   * however, Safari only released support for it in v16, so many of our users wont have it yet.
   */
  overflow: hidden;
  overflow: clip;
`;
