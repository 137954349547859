import type { ReactNode } from 'react';

import styled from 'styled-components';

import { Typography } from '../Typography/Typography';

export const RootBanner = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.capsuleBlue50};
  min-height: ${props => props.theme.space.s7};
  padding: ${props => props.theme.space.s4} ${props => props.theme.space.s5};
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.theme.mediaQueries.smallDown} {
    justify-content: center;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ${props => props.theme.mediaQueries.small} {
    flex-direction: row;
    align-items: center;
    margin-right: ${props => props.theme.space.s7};
  }
`;

const WhiteTitleText = styled(Typography)`
  color: white;
  padding-right: ${props => props.theme.space.s2};
`;

export type BannerProps = {
  titleText: string;
  subtitleText?: string | null;
  precedingIcon?: ReactNode;
  children?: ReactNode;
  className?: string;
};

export const Banner = ({ titleText, subtitleText, precedingIcon, children, className }: BannerProps) => {
  return (
    <RootBanner className={className}>
      {precedingIcon}

      <TextContainer>
        {/**
         * When subtitle text is truthy, design wants the title text bolded with the subtitle text
         * not bolded. When there is only a titleText, we apply the body variant (not bolded).
         */}
        <WhiteTitleText variant={!subtitleText ? 'body' : 'heading4'}>{titleText}</WhiteTitleText>

        {subtitleText && <WhiteTitleText variant={{ base: 'body', smallDown: 'body' }}>{subtitleText}</WhiteTitleText>}
      </TextContainer>

      {children}
    </RootBanner>
  );
};
