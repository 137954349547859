import { useEffect } from 'react';
import type { FC } from 'react';

import { SESSION_STORAGE_KEYS } from 'src/app/storage/sessionStorageKeys';

import { trackIdentify } from '../../capsule-tracking/analytics';
import { getHostnameFromURL } from '../../utils';

/**
 * Persist external referrers via sessionStorage for navigation between
 * consumer web apps
 *
 * Sends custom segment identify referrer event
 *
 */
export const EnsureReferrerIdentifyOnLoad: FC = () => {
  useEffect(() => {
    const referrer = document.referrer;
    const isExternalReferrer = !referrer.includes('capsule.com');
    if (isExternalReferrer) {
      sessionStorage.setItem(SESSION_STORAGE_KEYS.externalReferrer, referrer);
    }
    const persistedReferrer = sessionStorage.getItem(SESSION_STORAGE_KEYS.externalReferrer);

    // Note: Follows default amplitude referrer attribute behavior
    // initial_referrer and initial_referring_domain are `$setOnce` properties
    // manually defined in the Segment/Amplitude settings
    if (persistedReferrer) {
      const referrerDomain = getHostnameFromURL(persistedReferrer);
      trackIdentify(undefined, {
        referrer: persistedReferrer,
        referring_domain: referrerDomain,
        initial_referrer: persistedReferrer,
        initial_referring_domain: referrerDomain,
      });
    }
  }, []);
  return null;
};
