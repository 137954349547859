import type { ForwardRefExoticComponent, RefAttributes } from 'react';
import { forwardRef } from 'react';

import styled, { css } from 'styled-components';

import { UnstyledButton } from './UnstyledButton';

import type { UnstyledButtonProps } from './UnstyledButton';

export type ButtonVariant =
  | 'contained-primary-lg'
  | 'contained-secondary-lg'
  | 'contained-primary-md'
  | 'contained-secondary-md'
  | 'outline-lg'
  | 'outline-md'
  | 'text-md'
  | 'text-sm'
  | 'dark-outline-lg'
  | 'dark-outline-md'
  | 'dark-text-md'
  | 'dark-text-sm';

export type RelevantUnstyledButtonProps = Omit<
  UnstyledButtonProps,
  'inlineSpinnerProps' | 'type' | 'typographyVariant'
>;

export type SharedButtonProps = {
  /**
   * If you're looking for a "link" variant, please import the `ButtonWithLinkStyle` component instead.
   */
  variant: ButtonVariant;

  /**
   * You cannot define `type` as a prop. The type signatures differ slightly per button component based upon the
   * `type` attribute, so we've simplified things by exporting "Button", "SubmitButton", and "ResetButton" as
   * separate components.
   */
  // type?: never;
};

type RequiredIsSubmittingProp = Required<Pick<RelevantUnstyledButtonProps, 'isSubmitting'>>;

export type ButtonProps = Omit<RelevantUnstyledButtonProps, 'isSubmitting'> & SharedButtonProps;
export type SubmitButtonProps = Omit<RelevantUnstyledButtonProps, 'isDisabled' | 'isLoading'> &
  RequiredIsSubmittingProp &
  SharedButtonProps;
export type ResetButtonProps = Omit<RelevantUnstyledButtonProps, 'isLoading'> &
  RequiredIsSubmittingProp &
  SharedButtonProps;

const isDarkVariant = (variantName: ButtonVariant) => variantName.startsWith('dark');

/** MARK: Button Sizes */
const largeButton = css`
  padding: 1.4rem ${props => props.theme.space.s5};
`;

const mediumButton = css`
  padding: 0.6rem ${props => props.theme.space.s3};
`;

/** MARK: Contained Button */
const primaryContainedButton = css`
  background-color: ${props => props.theme.colors.capsuleRed60};

  @media (hover: hover) {
    &:hover:not(:active):not([aria-disabled='true']) {
      background-color: ${props => props.theme.colors.capsuleRed70};
    }
  }

  &:active:not([aria-disabled='true']) {
    background-color: ${props => props.theme.colors.capsuleRed80};
  }
`;

const secondaryContainedButton = css`
  background-color: ${props => props.theme.colors.capsuleBlue50};

  @media (hover: hover) {
    &:hover:not(:active):not([aria-disabled='true']) {
      background-color: ${props => props.theme.colors.capsuleBlue60};
    }
  }

  &:active:not([aria-disabled='true']) {
    background-color: ${props => props.theme.colors.capsuleBlue90};
  }
`;

const containedButtonStyles = css<Pick<SharedButtonProps, 'variant'>>`
  border-radius: 3px;
  color: ${props => props.theme.colors.white};

  ${({ variant }) => (variant === 'contained-primary-lg' || variant === 'contained-secondary-lg') && largeButton};
  ${({ variant }) => (variant === 'contained-primary-md' || variant === 'contained-secondary-md') && mediumButton};

  &:focus-visible:not(:active) {
    outline-offset: -4px;
    outline-width: 2px;
    outline-style: solid;
    outline-color: ${props => props.theme.colors.white};
  }

  ${({ variant }) =>
    (variant === 'contained-primary-lg' || variant === 'contained-primary-md') && primaryContainedButton};
  ${({ variant }) =>
    (variant === 'contained-secondary-lg' || variant === 'contained-secondary-md') && secondaryContainedButton};

  &[aria-disabled='true']:not(.inline-spinner) {
    background-color: ${props => props.theme.colors.capsuleGray10};
  }
`;

/** MARK: Outline Button */
const outlineButtonStyles = css<Pick<SharedButtonProps, 'variant'>>`
  ${({ variant }) => (variant === 'outline-lg' || variant === 'dark-outline-lg') && largeButton};
  ${({ variant }) => (variant === 'outline-md' || variant === 'dark-outline-md') && mediumButton};

  background-color: ${props => props.theme.colors.transparent};
  border-radius: ${({ theme }) => theme.radii.r100};
  border: 1px solid;

  ${({ variant, theme }) =>
    isDarkVariant(variant)
      ? css`
          border-color: ${theme.colors.white};
          color: ${theme.colors.white};

          @media (hover: hover) {
            &:hover:not(:active):not([aria-disabled='true']):not(:focus-visible) {
              background-color: ${theme.colors.transparent};
              box-shadow: 0 0 0 1px ${theme.colors.white};
            }
          }

          &:focus-visible,
          &:active:not([aria-disabled='true']) {
            background-color: ${theme.colors.white};
            border-color: ${theme.colors.white};
            color: ${theme.colors.capsuleBlue50};
            outline-width: 0;
          }

          &[aria-disabled='true']:not(.inline-spinner) {
            color: ${theme.colors.capsuleGray10};
            border-color: ${theme.colors.capsuleGray10};
            background-color: ${theme.colors.transparent};
            border-width: 1px;
            box-shadow: none;
          }

          &:focus-visible[aria-disabled='true'] {
            color: ${theme.colors.capsuleGray10};
            border-color: ${theme.colors.capsuleGray10};
            background-color: ${theme.colors.transparent};
            box-shadow: 0 0 0 1px ${theme.colors.capsuleGray10};
            border-width: 1px;
          }
        `
      : css`
          border-color: ${theme.colors.capsuleBlue50};
          color: ${theme.colors.capsuleBlue50};

          @media (hover: hover) {
            &:hover:not(:active):not([aria-disabled='true']):not(:focus-visible) {
              background-color: ${theme.colors.transparent};
              box-shadow: 0 0 0 1px ${theme.colors.capsuleBlue50};
            }
          }

          &:focus-visible,
          &:active:not([aria-disabled='true']) {
            background-color: ${theme.colors.capsuleBlue50};
            border-color: ${theme.colors.capsuleBlue50};
            color: ${theme.colors.white};
            outline-width: 0;
          }

          &[aria-disabled='true']:not(.inline-spinner) {
            color: ${theme.colors.capsuleGray10};
            border-color: ${theme.colors.capsuleGray10};
            background-color: ${theme.colors.transparent};
            border-width: 1px;
            box-shadow: none;
          }

          &:focus-visible[aria-disabled='true'] {
            color: ${theme.colors.capsuleGray10};
            border-color: ${theme.colors.capsuleGray10};
            background-color: ${theme.colors.transparent};
            box-shadow: 0 0 0 1px ${theme.colors.capsuleGray10};
            border-width: 1px;
          }
        `}
`;

/** MARK: Text Button */
const textButtonStyles = css<Pick<SharedButtonProps, 'variant'>>`
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-style: solid;
  text-underline-offset: ${props => props.theme.space.s1};
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;

  @media (hover: hover) {
    &:hover {
      text-decoration: none;
    }
  }

  &:active {
    text-decoration: underline 2px;
  }

  ${({ variant, theme }) =>
    isDarkVariant(variant)
      ? css`
          background: ${theme.colors.transparent};
          color: ${theme.colors.white};

          &:focus {
            text-decoration: underline 2px;
            border-color: ${theme.colors.transparent};
            color: ${theme.colors.white};
          }

          &[aria-disabled='true']:not(.inline-spinner) {
            color: ${theme.colors.capsuleGray10};
            text-decoration: underline 1px;
          }

          &:focus-visible[aria-disabled='true'] {
            color: ${theme.colors.capsuleGray10};
            text-decoration: underline 2px;
          }
        `
      : css`
          background: ${theme.colors.transparent};
          color: ${theme.colors.capsuleBlue50};

          &:focus {
            text-decoration: underline 2px;
            border-color: ${theme.colors.transparent};
            color: ${theme.colors.capsuleBlue50};
          }

          &[aria-disabled='true']:not(.inline-spinner) {
            color: ${theme.colors.capsuleGray10};
            text-decoration: underline 1px;
          }

          &:focus-visible[aria-disabled='true'] {
            color: ${theme.colors.capsuleGray10};
            text-decoration: underline 2px;
          }
        `}
`;

/**
 * The casted typings within the next few lines is dumb and should be unnecessary; however, react-docgen-typescript
 * incorrectly defines the types based on underlying components INSTEAD of exported interfaces. This is a workaround
 * to ensure that Storybook displays the correct typings.
 *
 * @see https://github.com/storybookjs/storybook/discussions/18644
 * @todo Revisit this in Storybook v7
 */
const ButtonBase = styled(UnstyledButton as unknown as ForwardRefExoticComponent<RelevantUnstyledButtonProps>)<
  Pick<SharedButtonProps, 'variant'> & UnstyledButtonProps & RefAttributes<HTMLButtonElement>
>`
  ${({ variant }) =>
    (variant === 'contained-primary-lg' ||
      variant === 'contained-primary-md' ||
      variant === 'contained-secondary-lg' ||
      variant === 'contained-secondary-md') &&
    containedButtonStyles};

  ${({ variant }) =>
    (variant === 'outline-lg' ||
      variant === 'outline-md' ||
      variant === 'dark-outline-md' ||
      variant === 'dark-outline-lg') &&
    outlineButtonStyles};

  ${({ variant }) =>
    (variant === 'text-md' || variant === 'text-sm' || variant === 'dark-text-md' || variant === 'dark-text-sm') &&
    textButtonStyles};
`;

/**
 * **There is a separate export per `type` of button.** If you need a "submit" type button, you'll need to import
 * `SubmitButton`. For "reset", use `ResetButton`. This is done because the types differ drastically to a point where
 * conditional typing will not work.
 *
 * Most properties of a typical button element are exposed, plus some custom properties for ease of use, (such as the
 * `isFullWidth` prop). If you're looking for the "link" button variant, please use the `ButtonWithLinkStyle` component
 * instead. The API was very different for both components so it wasn't simple to create a type that worked with both
 * interfaces.
 *
 * Lastly, as mentioned before, you need to deal with different exports when you care about the "type" of button. So,
 * the `type` prop is not exposed.
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ variant, ...rest }, ref) => {
  return (
    <ButtonBase
      {...rest}
      variant={variant}
      inlineSpinnerProps={variant.endsWith('lg') ? { size: 20, thickness: 2 } : { size: 16, thickness: 2 }}
      typographyVariant={variant === 'text-sm' ? 'calloutSmall' : 'callout'}
      type="button"
      ref={ref}
    />
  );
});

/**
 * **There is a separate export per `type` of button.** If you need a "button" type button, you'll need to import
 * `Button`. For "reset", use `ResetButton`. This is done because the types differ drastically to a point where
 * conditional typing will not work.
 *
 * Most properties of a typical button element are exposed, plus some custom properties for ease of use, (such as the
 * `isFullWidth` prop). If you're looking for the "link" button variant, please use the `ButtonWithLinkStyle` component
 * instead. The API was very different for both components so it wasn't simple to create a type that worked with both
 * interfaces.
 *
 * Lastly, as mentioned before, you need to deal with different exports when you care about the "type" of button. So,
 * the `type` prop is not exposed.
 */
export const SubmitButton = forwardRef<HTMLButtonElement, SubmitButtonProps>(({ variant, ...rest }, ref) => {
  return (
    <ButtonBase
      {...rest}
      variant={variant}
      inlineSpinnerProps={variant.endsWith('lg') ? { size: 20, thickness: 2 } : { size: 16, thickness: 2 }}
      typographyVariant={variant === 'text-sm' ? 'calloutSmall' : 'callout'}
      type="submit"
      ref={ref}
    />
  );
});

/**
 * **There is a separate export per `type` of button.** If you need a "submit" type button, you'll need to import
 * `SubmitButton`. For "button", use `Button`. This is done because the types differ drastically to a point where
 * conditional typing will not work.
 *
 * Most properties of a typical button element are exposed, plus some custom properties for ease of use, (such as the
 * `isFullWidth` prop). If you're looking for the "link" button variant, please use the `ButtonWithLinkStyle` component
 * instead. The API was very different for both components so it wasn't simple to create a type that worked with both
 * interfaces.
 *
 * Lastly, as mentioned before, you need to deal with different exports when you care about the "type" of button. So,
 * the `type` prop is not exposed.
 */
export const ResetButton = forwardRef<HTMLButtonElement, ResetButtonProps>(({ variant, ...rest }, ref) => {
  return (
    <ButtonBase
      {...rest}
      variant={variant}
      inlineSpinnerProps={variant.endsWith('lg') ? { size: 20, thickness: 2 } : { size: 16, thickness: 2 }}
      typographyVariant={variant === 'text-sm' ? 'calloutSmall' : 'callout'}
      type="reset"
      ref={ref}
    />
  );
});
