export const EVENT = {
  beginOnboarding: {
    name: 'Begin Onboarding',
    properties: ['pagepath', 'source'],
  },
  expandFaq: {
    name: 'Expand FAQ',
    properties: ['text'],
  },
  expandHomepageFaq: {
    name: 'Expand Homepage FAQs',
    properties: ['text'],
  },
  externalLinkClicked: {
    name: 'External Link Clicked',
    properties: ['externalLinkDestination'],
  },
  fieldFocused: {
    name: 'Field Focused',
    properties: ['pagepath', 'fieldname'],
  },
  headerGetStartedClicked: {
    name: 'Header - Get Started - Clicked',
    properties: [],
  },
  // TODO misspelling zip code carried over from consumer web. check if we can fix
  deliveryAreaEligibilityZipcodeFailed: {
    name: 'Delivery Area Eligibility Zipcode Failed',
    properties: ['zipCode', 'pagepath'],
  },
  deliveryAreaEligibilityZipcodeSuccessful: {
    name: 'Delivery Area Eligibility Zipcode Successful',
    properties: ['zipcode', 'pagepath'],
  },
  scrollReviews: {
    name: 'Scroll Homepage Reviews',
    properties: [],
  },
  viewHome: {
    name: 'View Home',
    properties: [],
  },
  viewFaqPage: {
    name: 'View FAQ',
    properties: ['source'],
  },
  viewFAQSection: {
    name: 'View FAQ Section',
    properties: ['source'],
  },
  viewReviewsSection: {
    name: 'View Reviews Section',
    properties: [],
  },
  viewZipcodeSection: {
    name: 'View Zipcode Section',
    properties: [],
  },
  viewNavBar: {
    name: 'View Nav Bar',
    properties: ['pagepath', 'is_logged_in'],
  },
};

export interface UnifiedEvent {
  name: string;
  properties: string[];
}

export const track = (event: UnifiedEvent, properties?: object) => {
  const analytics = window && window.analytics;

  if (!analytics) {
    console.error("Segment analytics isn't loaded on window");
    return;
  }

  analytics?.track(event.name, properties);
};

export const trackIdentify = (userId?: string, traits?: object) => {
  const analytics = window && window.analytics;

  if (!analytics) {
    console.error("Segment analytics isn't loaded on window");
    return;
  }

  analytics?.identify(userId, traits);
};
