import styled, { css } from 'styled-components';

/**
 * Note: `ScreenReaderOnly` (a component) is also available for use.
 *
 * This css is for rendering text for users of assitive technology. An element with these styles
 * will be read, but will be invisible. Not every screen reader is compatible with `aria-label`,
 * so this should be used instead.
 *
 * Note: If you use this CSS, we encourage you to apply it within a `span` so that you don't risk
 * CSS nesting validation issues. Please be aware of potential invalid HTML if you don't put it in
 * a span.
 *
 * One example: If attempting to create a custom radio or checkbox input, you will want the actual
 * input rendered, and - in order to style it without css - the order of the elements matter. This
 * means that a `ScreenReaderOnly` component would make it impossible to use sibling styles. Since,
 * `:has()` has limited browser support, this has been exposed for your needs.
 *
 * @example
 * <IconButton>
 *   <StarIcon />
 *   <ScreenReaderOnly>Mark as favorite</ScreenReaderOnly>
 * </IconButton>
 */
export const screenReaderOnlyCSS = css`
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
`;

/**
 * Note: `screenReaderOnlyCSS` is also available for use.
 *
 * This component is for rendering text for users of assitive technology. It will be read, but
 * will be invisible. Not every screen reader is compatible with `aria-label`, so this should be
 * used instead.
 *
 * This needs to be a span, so it can be nested within any element without an HTML validation
 * error.
 *
 * One example: If rendering a button with _only_ an icon attempting to convey meaning, also nest
 * that conveyed meaning (as text) as a child of ScreenReaderOnly. A more specific example of this
 * is with "X" Close buttons (the button meant to be clicked to dismiss something). The icon
 * readily indicates to seeing-eye users the purpose of the button; however, users of assistive
 * technology may not be able to _see_ the icon. Putting "Close" as the child of a ScreenReaderOnly
 * component will help them understand the button's functionality.
 *
 * @example
 * <IconButton>
 *   <StarIcon />
 *   <ScreenReaderOnly>Mark as favorite</ScreenReaderOnly>
 * </IconButton>
 */
export const ScreenReaderOnly = styled.span`
  ${screenReaderOnlyCSS};
`;
