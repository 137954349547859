// Also defined in src/app/client/consumer-web.ts - adjust there if this value changes
const AXIOS_TIMEOUT = 5 * 60 * 1000; // 5 minutes time-out, let the server time out, but slow connections can continue

const IGNORED_ERRORS = [
  'Network Error', // network failure
  `timeout of ${AXIOS_TIMEOUT}ms exceeded`, // network failure (e.g. ECONNABORTED)
  'Refresh Token has expired', // auth failure
  'User does not exist.', // auth failure
  'Request failed with status code 401', // auth failure
];

// dotenv doesn't evaluate in this file.
// We use a function so that sentry.*.config.js files can pass environment variables.
export const getDefaultConfig = capsuleEnvironment => {
  const isProduction = capsuleEnvironment === 'production';

  return {
    allowDuplicates: !isProduction,
    allowUrls: [
      /localhost/,
      /0\.0\.0\.0/,
      /capsulerx\.com/,
      /capsulecares\.com/,
      /capsule\.com/,
      /api\.capsulerx\.com/,
      /api\.staging\.capsulerx\.com/,
      /api\.dev\.capsulerx\.com/,
    ],
    autoBreadcrumbs: {
      xhr: true,
      console: true,
      dom: true,
      location: true,
    },
    beforeSend(event, hint) {
      const errorMessage = hint?.originalException?.message || '';

      // TODO: See if we can leverage `ignoredErrors` field instead.
      // Ignore auth errors and network errors
      if (IGNORED_ERRORS.includes(errorMessage)) return null;

      // Group errors by transaction
      const transaction = event.tags?.transaction || '';
      event.fingerprint = ['{{ default }}', String(transaction)];
      return event;
    },
    debug: !isProduction,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    enabled: process.env.NODE_ENV === 'production',
    environment: capsuleEnvironment,
    ignoreUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
    maxBreadcrumbs: 20,
    release: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    tracesSampleRate: 0.15,
  };
};
