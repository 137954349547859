import type { ButtonHTMLAttributes } from 'react';
import { forwardRef } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import { InlineSpinner } from '../InlineSpinner/InlineSpinner';
import { Typography } from '../Typography/Typography';

import type { InlineSpinnerProps } from '../InlineSpinner/InlineSpinner';
import type { TypographyVariant } from '../Typography/Typography';

export interface UnstyledButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'color' | 'disabled' | 'style' | 'type'> {
  'data-testid'?: string;

  inlineSpinnerProps?: InlineSpinnerProps;

  /**
   * If `true`, the `ContainedButton` instance will have its `width` property set to `"100%"`.
   * If `false`, the width is set to `"auto"`.
   *
   * @default false
   */
  isFullWidth?: boolean;

  /**
   * Should not be used on buttons with type "submit"
   *
   * @default false
   */
  isDisabled?: boolean;

  /**
   * @default false
   */
  isLoading?: boolean;

  /**
   * This property should only be used for buttons of type "reset" and "submit". It is used to set
   * `aria-disabled` as well as show or hide a loading spinner.
   *
   * @default false
   */
  isSubmitting?: boolean;

  type: 'button' | 'reset' | 'submit';

  typographyVariant?: TypographyVariant;
}

const UnstyledButtonBase = styled('button')<
  Partial<Omit<UnstyledButtonProps, 'isFullWidth'>> & Required<Pick<UnstyledButtonProps, 'isFullWidth'>>
>`
  align-items: center;
  border: 1px solid ${props => props.theme.colors.transparent};
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: none;
  padding: 0;
  width: ${props => (props.isFullWidth ? '100%' : 'auto')};

  &[aria-disabled='true'] {
    cursor: not-allowed;
  }

  &.inline-spinner {
    cursor: wait;
  }
`;

const DivWithPositionRelative = styled.div`
  position: relative;
`;

/**
 * The width, height, padding, margins, and border of this element are maintained, but the content itself is invisible.
 */
const InvisibleTextWithMaintainedDimensions = styled.span`
  opacity: 0;
  visibility: hidden;
`;

/**
 * Horizontally and vertically center the spinner _despite_ invisible (but rendered) content
 * existing within the button beside the spinner. Without centering with absolute positioning,
 * the invisible content would push the spinner to the side because it still occupies its own
 * box-model space.
 */
const ButtonLoadingSpinner = styled(InlineSpinner)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

/**
 * The `UnstyledButton` component is the root of all button variants.
 *
 * It should never be rendered as-is. It has "sane default" styling for you to compose a production-ready button.`
 *
 * If one day, we need a button that can only fire once (otherwise known as a button with 3 states), check out:
 * https://codepen.io/accessabilly/pen/VzeEMv
 */
export const UnstyledButton = forwardRef<HTMLButtonElement, UnstyledButtonProps>(
  (
    {
      inlineSpinnerProps,
      isDisabled,
      isFullWidth = false,
      isLoading = false,
      isSubmitting = false,
      onClick,
      typographyVariant = 'callout',
      ...props
    },
    ref
  ) => {
    const hasSpinner = isLoading || isSubmitting;

    return (
      <UnstyledButtonBase
        {...props}
        /**
         * `aria-disabled` is used instead of `disabled` because `disabled` makes the button invisible to assistive
         * technology. By using `aria-disabled` with JS & CSS, we can disable the button while still being
         * a11y-friendly.
         *
         * This is NOT the case for submit type buttons where the form is still submittable unless
         * we change the button to `disabled`.
         */
        aria-disabled={isDisabled || hasSpinner}
        disabled={props.type === 'submit' ? isDisabled || hasSpinner : undefined}
        onClick={isDisabled || hasSpinner ? undefined : onClick}
        className={classNames(props.className, { ['inline-spinner']: hasSpinner })}
        isFullWidth={isFullWidth}
        ref={ref}
      >
        <Typography as={DivWithPositionRelative} variant={typographyVariant}>
          {hasSpinner ? (
            <>
              <ButtonLoadingSpinner size={14} thickness={1} {...inlineSpinnerProps} />
              <InvisibleTextWithMaintainedDimensions>{props.children}</InvisibleTextWithMaintainedDimensions>
            </>
          ) : (
            <>{props.children}</>
          )}
        </Typography>
      </UnstyledButtonBase>
    );
  }
);
