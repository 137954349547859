import type { ChangeEvent, TextareaHTMLAttributes, ChangeEventHandler } from 'react';
import { useState, forwardRef, useEffect } from 'react';

import styled from 'styled-components';

import { ScreenReaderOnly } from '../../ScreenReaderOnly/ScreenReaderOnly';
import { Typography } from '../../Typography/Typography';

import type { RequiredLabelProp } from '../types';

const StyledTextarea = styled('div')<{ hasError?: boolean }>`
  color: ${({ theme, hasError }) => (hasError ? theme.colors.capsuleRed60 : theme.colors.capsuleGreen60)};
`;

const StyledTextareaFooter = styled('div')`
  margin-top: ${({ theme }) => theme.space.s2};
  display: flex;
`;

const StyledTextareaWordCount = styled(Typography).attrs({ variant: 'small' })<{ hasError?: boolean }>`
  color: ${({ theme, hasError }) => (hasError ? theme.colors.capsuleRed60 : theme.colors.capsuleGray50)};
  text-align: right;
  block-size: fit-content;
  padding-left: ${({ theme }) => theme.space.s4};
  width: fit-content;
`;

export const TextareaPrimitive = styled(Typography).attrs({ as: 'textarea', variant: 'body' })<
  TextareaHTMLAttributes<HTMLTextAreaElement>
>`
  resize: none;
  width: 100%;
  border-radius: ${({ theme }) => theme.radii.r100};
  padding: ${({ theme }) => theme.space.s4};
  box-sizing: border-box;
  border: solid 1px;
  &:focus {
    outline: solid 1px;
    outline-color: ${({ theme }) => theme.colors.capsuleGreen60};
  }
  display: flex;
  flex-grow: 1;

  /** Colors */
  border-color: ${({ theme }) => theme.colors.capsuleGreen60};
  color: ${({ theme }) => theme.colors.capsuleBlue50};
  outline-color: ${({ theme }) => theme.colors.capsuleGreen60};
  &::placeholder {
    color: ${({ theme }) => theme.colors.capsuleGray50};
  }

  &[aria-invalid='true'] {
    border-color: ${({ theme }) => theme.colors.capsuleRed60};
    color: ${({ theme }) => theme.colors.capsuleRed60};
    outline-color: ${({ theme }) => theme.colors.capsuleRed60};
  }

  /**
  * We want the word count to only be visible when the field is focused or if the user
  * has gone over the limit.
  */
  &:not(:focus) + ${StyledTextareaFooter} ${StyledTextareaWordCount} {
    visibility: hidden;
  }

  &:focus
    + ${StyledTextareaFooter}
    ${StyledTextareaWordCount},
    &[aria-invalid='true']
    + ${StyledTextareaFooter}
    ${StyledTextareaWordCount} {
    visibility: visible;
  }
`;

export const LabelElement = styled(Typography).attrs({ variant: 'overline' })`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.s2};
  margin-bottom: ${({ theme }) => theme.space.s2};

  & > img,
  & > svg {
    width: ${({ theme }) => theme.space.s5};
    height: ${({ theme }) => theme.space.s5};
  }
`;

const StyledTextareaFooterMsg = styled('div')`
  flex-grow: 1;
`;

const StyledTextareaError = styled(Typography).attrs({ variant: 'small' })`
  word-wrap: break-word;
`;

const StyledTextareaDescription = styled(Typography).attrs({ variant: 'small' })`
  color: ${({ theme }) => theme.colors.capsuleGray50};
`;

export interface TextareaProps
  // name is omitted from TextareaHTMLAttributes<HTMLTextAreaElement> and then added to the interface to make it required.
  extends Omit<
      TextareaHTMLAttributes<HTMLTextAreaElement>,
      'children' | 'name' | 'placeholder' | 'style' | 'type' | 'rows'
    >,
    RequiredLabelProp {
  name: string;
  /**
   * @default 2
   */
  rows?: 2 | 3 | 4;
  className?: string;
  /**
   * Validation is still required to prevent form submission; however, the error message is
   * determined by the design system documentation (not by a specific feature).
   */
  maxLength?: number;
  placeholder?: string;
  description?: string;
  error?: string;
  /**
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/enterkeyhint#values
   * @default 'enter'
   */
  enterKeyHint?: 'enter' | 'done' | 'go' | 'next' | 'previous' | 'search' | 'send';
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ label, value, rows = 2, name, id, error, description, maxLength, ...props }: TextareaProps, ref) => {
    const [charCount, setCharCount] = useState(`${value ?? ''}`.length);

    const handleInputChange: ChangeEventHandler<HTMLTextAreaElement> = (e: ChangeEvent<HTMLTextAreaElement>) => {
      setCharCount(e.target.value.length);
      props?.onChange?.(e);
    };

    useEffect(() => {
      const defaultValueLen = value ? value.toString().length : 0;
      setCharCount(defaultValueLen);
    }, [value]);

    const identifier = id || name;
    const helperIdentifier = `${identifier}-helper`;
    const isLabelHidden = label?.isHidden === true;

    const isCharCountOverLimit = maxLength && charCount > maxLength;
    const charactersOverLimit = isCharCountOverLimit ? charCount - maxLength : 0;

    const hasError = !!error || charactersOverLimit > 0;

    /**
     * The wording of "can" is on purpose because the maxLength UI is only visible if the user
     * is over the limit OR if the field is focused.
     */
    const canDisplayFooter = hasError || !!description || !!maxLength;

    return (
      <StyledTextarea hasError={hasError}>
        {isLabelHidden ? (
          <ScreenReaderOnly as="label" htmlFor={identifier}>
            {label?.content}
          </ScreenReaderOnly>
        ) : (
          <LabelElement as="label" htmlFor={identifier}>
            {label?.content}
          </LabelElement>
        )}

        <TextareaPrimitive
          as="textarea"
          {...props}
          name={name}
          id={identifier}
          value={value}
          ref={ref}
          rows={rows}
          onChange={handleInputChange}
          aria-invalid={hasError}
          aria-describedby={helperIdentifier.concat(props['aria-describedby'] ?? '')}
        />

        {canDisplayFooter && (
          <StyledTextareaFooter id={helperIdentifier}>
            <StyledTextareaFooterMsg>
              <StyledTextareaError>
                {isCharCountOverLimit
                  ? `${charactersOverLimit} ${charactersOverLimit > 1 ? 'characters' : 'character'} too long`
                  : error}
              </StyledTextareaError>

              <StyledTextareaDescription>{description}</StyledTextareaDescription>
            </StyledTextareaFooterMsg>

            {!!maxLength && (
              <StyledTextareaWordCount as="div" hasError={hasError} aria-live="polite">
                <ScreenReaderOnly>Characters used: </ScreenReaderOnly>
                {charCount}/{maxLength}
              </StyledTextareaWordCount>
            )}
          </StyledTextareaFooter>
        )}
      </StyledTextarea>
    );
  }
);
