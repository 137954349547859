import type { InputHTMLAttributes, LabelHTMLAttributes } from 'react';

import styled from 'styled-components';

import { Typography } from '../../Typography/Typography';
import { truncatedEllipsizedTextCSS } from '../../utilityCSS';

export const LabelElement = styled(Typography).attrs({ as: 'label' })<LabelHTMLAttributes<HTMLLabelElement>>`
  order: 1;
`;

export const VisibleLabel = styled(Typography).attrs({ variant: 'body' })<{ size?: 'sm' | 'md' }>`
  font-size: ${props => (props.size === 'sm' ? '1rem' : '1.2rem')};
  display: inline-block;
  transform: scale(1.3334) translateX(0%) translateY(105%); // 1.3334 * 1.2rem = 1.6rem (font size of "body")
  transform-origin: 0% 50% 0;
  transition: transform ${({ theme }) => theme.animation.typical};

  ${({ theme }) => theme.mediaQueries.prefersReducedMotion} {
    transition: none;
  }
`;

/**
 * This is _just_ the input element. There's no label element, nor is there an error or description node. There are no
 * styles for any potential label or error elements either. If you do use this primitive, please ensure that you
 * provide a label element yourself, and ensure to connect any description or error node to the input element with
 * `aria-describedby` (as done in the source code for the TextField component).
 *
 * You can look at [this CSS Tricks article](https://css-tricks.com/html-inputs-and-labels-a-love-story) for pointers
 * in engineering a text input with accessibility in mind.
 */
export const TextInputPrimitive = styled(Typography).attrs({ as: 'input', variant: 'body' })<
  InputHTMLAttributes<HTMLInputElement>
>`
  --TextInputPrimitiveTransitionValue: color ${({ theme }) => theme.animation.typical},
    border-color ${({ theme }) => theme.animation.typical};
  text-overflow: ellipsis;

  /** reset */
  appearance: none;
  background: ${({ theme }) => theme.colors.transparent};
  border-radius: 0;
  border: 0px solid transparent;
  box-shadow: inset 0 0 0 0 ${({ theme }) => theme.colors.transparent};
  outline: none;
  /** ***** */

  padding: ${({ theme }) => theme.space.s2} 0;

  /** Bottom border width */
  border-bottom-width: var(--textInputPrimitiveBottomBorderWidth, 0.1rem);
  margin-bottom: var(--textInputPrimitiveBottomBorderWidth, 0.1rem);
  &:focus {
    border-bottom-width: calc(var(--textInputPrimitiveBottomBorderWidth, 0.1rem) * 2);
    margin-bottom: 0;
  }

  /** Colors */
  border-bottom-color: ${({ theme }) => theme.colors.capsuleGreen60};
  color: ${({ theme }) => theme.colors.capsuleBlue50};
  transition: var(--TextInputPrimitiveTransitionValue);
  &[aria-invalid='true'] {
    border-bottom-color: ${({ theme }) => theme.colors.capsuleRed60};
    transition: var(--TextInputPrimitiveTransitionValue);
  }
`;

/** Only defined for the express purpose of being composed. Please don't render directly. */
export const TextFieldHelper = styled(Typography).attrs({ variant: 'small' })`
  order: 3;
  height: var(--textFieldHelperHeight);
  position: absolute;
  bottom: 0;
  width: 100%;
  ${truncatedEllipsizedTextCSS}
`;

export const TextFieldDescription = styled(TextFieldHelper)`
  color: ${({ theme }) => theme.colors.capsuleGray50};
`;

export const TextFieldError = styled(TextFieldHelper)`
  color: ${({ theme }) => theme.colors.capsuleRed60};
`;

export const AdornmentContainer = styled(Typography)<{ bottom?: string }>`
  z-index: ${({ theme }) => theme.zIndex.z2};
  box-sizing: border-box;
  width: var(--textFieldMaxAdornmentSize);
  height: var(--textFieldMaxAdornmentSize);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  /** border-bottom width + margin-bottom + height of helper*/
  bottom: ${props =>
    props.bottom
      ? props.bottom
      : 'calc(var(--textFieldHelperHeight) + (var(--textInputPrimitiveBottomBorderWidth) * 2))'};

  &[data-side='left'] {
    left: 0;
  }

  &[data-side='right'] {
    right: 0;
  }

  /* TODO: Replace "button svg" with IconButton selector */
  & *:not(button),
  & button svg {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: var(--textFieldAdornmentPadding);
  }

  & button * {
    padding: initial;
  }
`;

/**
* A **LOT** of the CSS is embedded in this one element for a few reasons.
* 1. We want to ensure that all of the TextField's sub-elements receive styling, but we want the `TextInputPrimitive`
  to be stylistically "dumb" regarding the elements around it (since it's exported separately).
* 2. If `TextInputPrimitive` _is_ used, most of the relevant CSS to the Field's styling is in one place allowing devs
  to easily emulate it.
* 3. We still have a lot of CSS specificity wars going on within the application, this can help us combat them a bit.
*/
export const TextFieldTopLevelElement = styled.div<{ inputBottomPadding?: string; adornmentSize?: 'sm' | 'md' }>`
  /* This CSS variable is defined in the top-level element so it's in scope for all children. */
  --textInputPrimitiveBottomBorderWidth: 0.1rem;
  --textFieldMaxAdornmentSize: ${props => (props.adornmentSize === 'md' ? '4.2rem' : '3.6rem')};
  --textFieldAdornmentPadding: 0.6rem;
  --textFieldHelperHeight: 1.6rem;

  position: relative;
  display: flex;
  flex-direction: column;
  cursor: text;
  color: ${({ theme }) => theme.colors.capsuleGreen60};

  /** we reserve space for helper text in case it renders (to avoid layout shift) */
  padding-bottom: ${props => (props.inputBottomPadding ? props.inputBottomPadding : 'var(--textFieldHelperHeight)')};

  & input {
    order: 2;
    z-index: ${({ theme }) => theme.zIndex.z1}; // so that a label can be positioned above the input element
  }

  /** If user's input has value or is focused, the label appears above the input box model. */
  & input:focus ~ label *,
  & input:active ~ label *,
  & input:not(:placeholder-shown) ~ label * {
    transform: none;
  }

  & input[aria-invalid='true'] ~ label {
    color: ${({ theme }) => theme.colors.capsuleRed60};
  }

  &[data-adorned='left'] input,
  &[data-adorned='left'] label {
    padding-left: calc(var(--textFieldMaxAdornmentSize) + var(--textFieldAdornmentPadding));
  }

  &[data-adorned='right'] input,
  &[data-adorned='right'] label {
    padding-right: calc(var(--textFieldMaxAdornmentSize) + var(--textFieldAdornmentPadding));
  }
`;
