import type { ButtonHTMLAttributes } from 'react';
import { forwardRef } from 'react';

import styled, { css } from 'styled-components';

import { UnstyledButton } from './UnstyledButton';

export interface ButtonWithLinkStyleProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'color' | 'disabled' | 'type'> {
  'data-testid'?: string;

  /**
   * @default 'base'
   */
  variant?: 'base' | 'dark';
}

const BaseButtonWithLinkStyle = styled(UnstyledButton).attrs({
  typographyVariant: 'body',
  type: 'button',
})<ButtonWithLinkStyleProps>`
  background: ${props => props.theme.colors.transparent};

  @media (hover: hover) {
    &:hover {
      text-decoration: underline 1px;
      text-underline-offset: 2px;
    }
  }

  &:focus-visible:not(:active) {
    border-radius: 3px;
    outline: 1px solid;
  }

  ${({ theme, variant }) =>
    variant === 'dark'
      ? css`
          color: ${theme.colors.capsuleGreen50};

          &:active {
            color: ${theme.colors.capsuleGreen10};
          }

          &:focus:not(:active) {
            outline-color: ${theme.colors.capsuleGreen50};
          }
        `
      : css`
          color: ${theme.colors.capsuleGreen60};

          &:active {
            color: ${theme.colors.capsuleGreen80};
          }

          &:focus:not(:active) {
            outline-color: ${theme.colors.capsuleGreen60};
          }
        `};
`;

/**
 * The `ButtonWithLinkStyle` component only exposes the properties of a typical button element. The buttons with
 * link-like appearances ended with an API that differed from the base `Button` component so much that typing was
 * difficult. Instead, it has been exported separately.
 *
 * @see https://capsulerx.slack.com/archives/CP5F1V7J7/p1656601529241019
 */
export const ButtonWithLinkStyle = forwardRef<HTMLButtonElement, ButtonWithLinkStyleProps>((props, ref) => {
  return <BaseButtonWithLinkStyle {...props} ref={ref} />;
});
