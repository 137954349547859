import styled, { css } from 'styled-components';
import type { StyledComponentInnerOtherProps, StyledComponentPropsWithRef } from 'styled-components';

import type { ThemeMediaQueryType } from '../../theme';

export enum TypographyVariantEnum {
  'body' = 'body',
  'bodyHeader' = 'bodyHeader',
  'small' = 'small',
  'overline' = 'overline',
  'callout' = 'callout',
  'calloutSmall' = 'calloutSmall',
  'heading1' = 'heading1',
  'heading2' = 'heading2',
  'heading3' = 'heading3',
  'heading4' = 'heading4',
  'displayXl' = 'displayXl',
  'displayXxl' = 'displayXxl',
  'displayXxxl' = 'displayXxxl',
}

export type TypographyVariant = keyof typeof TypographyVariantEnum;

type MultiVariantType = { base: TypographyVariant } & { [key in ThemeMediaQueryType]?: TypographyVariant };
type VariantType = MultiVariantType | TypographyVariant;

export const bodyVariantCSS = css`
  font-family: ${props => props.theme.fonts.circular};
  font-size: ${props => props.theme.fontSizes.body};
  font-weight: ${props => props.theme.fontWeights.book};
  line-height: ${props => props.theme.lineHeights.body};
`;

const bodyHeaderVariantCSS = css`
  font-family: ${props => props.theme.fonts.circular};
  font-size: ${props => props.theme.fontSizes.small};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: ${props => props.theme.lineHeights.body};
  letter-spacing: ${props => props.theme.letterSpacings.one};
`;

const smallVariantCSS = css`
  font-family: ${props => props.theme.fonts.circular};
  font-size: ${props => props.theme.fontSizes.small};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: ${props => props.theme.lineHeights.small};
`;

const overlineVariantCSS = css`
  font-family: ${props => props.theme.fonts.circular};
  font-size: ${props => props.theme.fontSizes.overline};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: ${props => props.theme.lineHeights.overline};
  letter-spacing: ${props => props.theme.letterSpacings.one};
  text-transform: ${props => props.theme.textTransforms.allCaps};
`;

const calloutVariantCSS = css`
  font-family: ${props => props.theme.fonts.mercury};
  font-size: ${props => props.theme.fontSizes.callout};
  font-weight: ${props => props.theme.fontWeights.roman};
  line-height: ${props => props.theme.lineHeights.callout};
  letter-spacing: ${props => props.theme.letterSpacings.one};
  text-transform: ${props => props.theme.textTransforms.allCaps};
`;

const calloutSmallVariantCSS = css`
  font-family: ${props => props.theme.fonts.mercury};
  font-size: ${props => props.theme.fontSizes.calloutSmall};
  font-weight: ${props => props.theme.fontWeights.roman};
  line-height: ${props => props.theme.lineHeights.calloutSmall};
  letter-spacing: ${props => props.theme.letterSpacings.one};
  text-transform: ${props => props.theme.textTransforms.allCaps};
`;

const heading1VariantCSS = css`
  font-family: ${props => props.theme.fonts.circular};
  font-size: ${props => props.theme.fontSizes.headerOne};
  font-weight: ${props => props.theme.fontWeights.bold};
  line-height: ${props => props.theme.lineHeights.headerOne};
`;

const heading2VariantCSS = css`
  font-family: ${props => props.theme.fonts.circular};
  font-size: ${props => props.theme.fontSizes.headerTwo};
  font-weight: ${props => props.theme.fontWeights.bold};
  line-height: ${props => props.theme.lineHeights.headerTwo};
`;

const heading3VariantCSS = css`
  font-family: ${props => props.theme.fonts.circular};
  font-size: ${props => props.theme.fontSizes.headerThree};
  font-weight: ${props => props.theme.fontWeights.bold};
  line-height: ${props => props.theme.lineHeights.headerThree};
`;

const heading4VariantCSS = css`
  font-family: ${props => props.theme.fonts.circular};
  font-size: ${props => props.theme.fontSizes.headerFour};
  font-weight: ${props => props.theme.fontWeights.bold};
  line-height: ${props => props.theme.lineHeights.headerFour};
`;

const displayXlVariantCSS = css`
  font-family: ${props => props.theme.fonts.circular};
  font-size: ${props => props.theme.fontSizes.displayXL};
  font-weight: ${props => props.theme.fontWeights.bold};
  line-height: ${props => props.theme.lineHeights.displayXL};
`;

const displayXxlVariantCSS = css`
  font-family: ${props => props.theme.fonts.circular};
  font-size: ${props => props.theme.fontSizes.displayXXL};
  font-weight: ${props => props.theme.fontWeights.bold};
  line-height: ${props => props.theme.lineHeights.displayXXL};
`;

const displayXxxlVariantCSS = css`
  font-family: ${props => props.theme.fonts.circular};
  font-size: ${props => props.theme.fontSizes.displayXXXL};
  font-weight: ${props => props.theme.fontWeights.bold};
  line-height: ${props => props.theme.lineHeights.displayXXXL};
`;

const TYPOGRAPHY_MAPPING = {
  displayXxxl: displayXxxlVariantCSS,
  displayXxl: displayXxlVariantCSS,
  displayXl: displayXlVariantCSS,

  heading1: heading1VariantCSS,
  heading2: heading2VariantCSS,
  heading3: heading3VariantCSS,
  heading4: heading4VariantCSS,

  body: bodyVariantCSS,
  bodyHeader: bodyHeaderVariantCSS,
  small: smallVariantCSS,
  overline: overlineVariantCSS,

  callout: calloutVariantCSS,
  calloutSmall: calloutSmallVariantCSS,
};

const getMultiVariantStyles = (variant: MultiVariantType) => {
  const { base, ...otherVariants } = variant;
  const variantEntries = Object.entries(otherVariants) as [ThemeMediaQueryType, TypographyVariant][];

  const style = [
    css`
      ${TYPOGRAPHY_MAPPING[base]}
    `,
  ];

  variantEntries.forEach(([breakpoint, variant]) => {
    style.push(css`
      ${props => props.theme.mediaQueries[breakpoint]} {
        ${TYPOGRAPHY_MAPPING[variant]}
      }
    `);
  });

  return style;
};

/**
 * The `Typography` component should be used for every all text.
 *
 * You can include one variant, or multiple variants for responsive text. If you are using multiple variants, the desktop
 * key should be `base`, and the other keys should be the relevant media breakpoint.
 *
 * @example
 * <Typography variant="displayXl">
 *
 * @example
 * <Typography variant={{ base: 'displayXl', smallDown: 'heading1' }}>
 *
 * By default, the underlying HTML is a div, but the polymorphic "as" prop is exposed and should be used.
 *
 * You should never be defining font-size, font-weight, font-family, line-height, nor letter-spacing in any element.
 *
 * *Note*: We purposefully don't expose any CSS properties. You can either extend this component OR - since this component's
 * color is defined as `currentColor` - you can simply define a parent element's color to get Typography colored.
 *
 * @example
 * const BlueBodyText = styled(Typography)`color: blue;`;
 *
 * @example
 * const ContainerWithBlueColor = styled.div`color: blue;`;
 * return <ContainerWithBlueColor><Typography /></ContainerWithBlueColor>;
 *
 * @see https://css-tricks.com/currentcolor/
 */
export const Typography = styled.div<{
  /**
   * @default body
   */
  variant?: VariantType;
}>`
  color: currentColor;
  text-decoration: inherit;

  ${({ variant }) => {
    if (!variant) {
      return bodyVariantCSS;
    }

    // custom types are not included in compiled JS
    if (typeof variant === 'string') {
      return TYPOGRAPHY_MAPPING[variant];
    }

    return getMultiVariantStyles(variant);
  }}
`;

/**
 * @deprecated USE AT YOUR OWN RISK
 *
 * `styled-components` makes it impossible to create a type that is aware of its own polymorphism.
 *
 * The type as-written is correct for "div" elements. This is VERY important to note when dealing with ref
 * forwarding and extending this type.
 */
export type TypographyProps = StyledComponentPropsWithRef<typeof Typography> &
  StyledComponentInnerOtherProps<typeof Typography>;
