import type { InputHTMLAttributes } from 'react';
import { forwardRef } from 'react';

import { useToggle } from '@react-hookz/web';
import styled from 'styled-components';

import { UnstyledButton } from '../../Button/UnstyledButton';
import { ScreenReaderOnly } from '../../ScreenReaderOnly/ScreenReaderOnly';
import { TextField } from '../TextField/TextField';

import type { TextFieldProps } from '../TextField/TextField';
import type { RequiredLabelProp, AutoCompleteOptions } from '../types';

export interface PasswordFieldProps
  extends Omit<
      InputHTMLAttributes<HTMLInputElement>,
      'autoComplete' | 'children' | 'name' | 'placeholder' | 'style' | 'type'
    >,
    Pick<TextFieldProps, 'name' | 'error' | 'description' | 'data-testid'>,
    RequiredLabelProp {
  /**
   * For accessibility reasons, autocomplete should always be defined. For password inputs, it's either a new password
   * (for registration and reset forms) or a current password (for login forms).
   *
   * @see https://incl.ca/show-hide-password-accessibility-and-password-hints-tutorial/#autocomplete
   */
  autoComplete: Extract<AutoCompleteOptions, 'current-password' | 'new-password'>;

  onVisibilityToggleClick?: ((isNextStateVisible: boolean) => void) | (() => void);
}

const TogglePasswordVisibilityButton = styled(UnstyledButton).attrs({
  type: 'button',
  role: 'switch',
})`
  appearance: none;
  border: none;
  border-radius: ${({ theme }) => theme.radii.r100};
  background-color: ${({ theme }) => theme.colors.transparent};
  cursor: pointer;

  & svg,
  & svg > path {
    color: ${({ theme }) => theme.colors.capsuleBlue50};
    fill: ${({ theme }) => theme.colors.capsuleBlue50};
  }

  &:focus-visible {
    outline: 1px solid ${({ theme }) => theme.colors.capsuleBlue50};
  }
`;

const invisibilityIcon = (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="m7.88 18.54.54.23a10 10 0 0 0 7.16 0c1.77-.68 3.38-2.15 6.6-5.1.57-.52.85-.78.98-1.07a1.5 1.5 0 0 0 0-1.2c-.13-.29-.41-.55-.98-1.07-1.3-1.18-2.32-2.13-3.21-2.88l-4.02 4.01a3.01 3.01 0 0 1-3.49 3.5l-3.58 3.58Zm6.02-8.86a3 3 0 0 0-4.22 4.22l-3.54 3.54a65.98 65.98 0 0 1-4.32-3.77 3.85 3.85 0 0 1-.98-1.07 1.5 1.5 0 0 1 0-1.2c.13-.29.41-.55.98-1.07 3.22-2.95 4.83-4.42 6.6-5.1a10 10 0 0 1 7.16 0c.6.23 1.17.54 1.8.98L13.9 9.68Z"
    />
    <path stroke="currentColor" strokeWidth="2" d="M20 3 3 20" />
  </svg>
);

const visibilityIcon = (
  <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.18 13.67c.57-.52.85-.78.98-1.07a1.5 1.5 0 0 0 0-1.2c-.13-.29-.41-.55-.98-1.07-3.22-2.95-4.82-4.42-6.6-5.1a10 10 0 0 0-7.16 0c-1.77.68-3.38 2.15-6.6 5.1-.57.52-.85.78-.98 1.07a1.5 1.5 0 0 0 0 1.2c.13.29.41.55.98 1.07 3.22 2.95 4.83 4.42 6.6 5.1a10 10 0 0 0 7.16 0c1.78-.68 3.38-2.15 6.6-5.1ZM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
    />
  </svg>
);

// tslreid === "toggle status live region element id"
const toggleStatusLiveRegionElementId = 'pw-input-tslreid';

// vbtneid === "visibility button element id"
const visibilityButtonElementId = 'pw-input-vbtneid';

/**
 * This is an input element with `type="password"`, which is a text field that masks the user's input. The abstraction
 * so far is only for the purposes of logging in and registering. If you need an input with type="password" for a
 * different purpose, please consider using the `TextField` component instead temporarily ignoring the TS error you
 * will encounter when defining type="password". After you've done that, discuss the new use case in
 * [#design-system](https://capsulerx.slack.com/archives/C030NMA4HDF) on Slack.
 */
export const PasswordField = forwardRef<HTMLInputElement, PasswordFieldProps>(
  ({ name, ...restOfProps }, forwardedRef) => {
    const [isInputValueVisible, toggleInputValueVisibility] = useToggle(false);

    // we use a div (instead of a fragment) here because the SR-only element would ruin many layout styles
    return (
      <div>
        {/**
         * VoiceOver nor NVDA announces state changes to aria-pressed, so we help those users out with this.
         * @see https://a11ysupport.io/tech/aria/aria-pressed_attribute#support-table-4
         */}
        <ScreenReaderOnly id={toggleStatusLiveRegionElementId} aria-live="polite">
          {name} value is currently {isInputValueVisible ? 'visible' : 'hidden'}.
        </ScreenReaderOnly>

        <TextField
          /**
           * We prevented the use of type="password" on TextField to encourage usage of this component. This is
           * essentially the one place where `type` is actually allowed to be "password" despite our component's
           * interface
           */
          // @ts-expect-error - Sacrificing internal type-safety for external DX.
          type={isInputValueVisible ? 'text' : 'password'}
          name={name}
          aria-describedby={`${toggleStatusLiveRegionElementId} ${visibilityButtonElementId}`}
          aria-required // required attribute has many a11y issues, so we're using aria-required instead.
          adornment={{
            right: (
              <TogglePasswordVisibilityButton
                onClick={() => toggleInputValueVisibility()}
                role="switch"
                aria-pressed={isInputValueVisible}
                id={visibilityButtonElementId}
              >
                <>
                  {/* Don't swap text content because announcement would confuse a user that engaged the toggle */}
                  <ScreenReaderOnly>Toggle password visibility.</ScreenReaderOnly>

                  {isInputValueVisible ? invisibilityIcon : visibilityIcon}
                </>
              </TogglePasswordVisibilityButton>
            ),
          }}
          ref={forwardedRef}
          {...restOfProps}
        />
      </div>
    );
  }
);
