import type { AppContext, AppInitialProps, AppProps } from 'next/app';
import App from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';

import * as snippet from '@segment/snippet';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import 'src/app/scss/index.scss';
import 'src/app/styles/fonts.css';
import { useFontCacheServiceWorker } from 'src/hooks/useFontCacheServiceWorker';
import { EnsureReferrerIdentifyOnLoad } from 'src/landing-pages/capsule-components/EnsureReferrerIdentifyOnLoad';
import { PATH_TO_NAME } from 'src/landing-pages/consts';
import { theme } from 'src/styles/theme';

// html font size sets base for rem
const GlobalStyle = createGlobalStyle`
  /* overide browser defaults */
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* smoothing helps render fonts properly */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  p {
    margin: 0;
    padding: 0;
  }
  html {
    font-size: 10px;
  }
  .grecaptcha-badge {
    visibility: hidden;
  }

  /**
   * Z-Index Wars!
   *
   * We want to avoid the z-index wars, but we do have to make old code work with new. By moving any z-index overrides
   * here, we can centralize them and more easily remove them later as we continue to strive towards typical stacking
   * orders.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/Stacking_without_z-index
   * @see https://sherryhsu.medium.com/how-to-avoid-z-index-war-9d22b44ca61a
   */
  .cds-dialog-overlay,
  .cds-dialog-overlay *,
  .cds-dialog-content,
  .cds-dialog-content * {
    z-index: 3;
  }
`;
type AppOwnProps = { hostname: string };

const renderSnippet = (path: string) => {
  // TODO The snippet will make a page call after loading if we want.
  // We should just make page calls ourselves on page load

  const opts = {
    apiKey: process.env.NEXT_PUBLIC_SEGMENT_TOKEN,
    page: {
      // Casting the type here is fine because if `router.pathname` is NOT the right value, name is just undefined.
      name: PATH_TO_NAME[path.toLowerCase() as keyof typeof PATH_TO_NAME],
      properties: {
        path,
      },
    },
  };

  return snippet.min(opts);
};

export default function CapsuleApp({ Component, pageProps, hostname }: AppProps & AppOwnProps) {
  const { asPath, isReady } = useRouter();
  const isClearRxPage = hostname.includes('clearrx.co');
  const canonicalHost = isClearRxPage ? 'https://www.clearrx.co' : 'https://www.capsule.com';
  const canonicalHref = canonicalHost.concat(asPath);

  useFontCacheServiceWorker();

  return (
    <>
      <Head>
        {/*
         * We don't want to delay Segment if we don't have to. So, initialize it on landing pages, but hold off for
         * logged-in routes (until Next.js router is aware of the true URL path).
         */}
        {asPath !== '/[...app]' && (
          <>
            <script dangerouslySetInnerHTML={{ __html: renderSnippet(asPath) }} />
            <link rel="canonical" href={canonicalHref} />
          </>
        )}
        {asPath === '/[...app]' && isReady && (
          <>
            <script dangerouslySetInnerHTML={{ __html: renderSnippet(asPath) }} />
            <link rel="canonical" href={canonicalHref} />
          </>
        )}

        <meta content="width=device-width, height=device-height, initial-scale=1.0" name="viewport" />
        <meta name="og:type" content="website" />
      </Head>
      <GlobalStyle />
      <EnsureReferrerIdentifyOnLoad />
      <ThemeProvider theme={theme}>
        <Component {...pageProps} />
      </ThemeProvider>
    </>
  );
}

CapsuleApp.getInitialProps = async (context: AppContext): Promise<AppOwnProps & AppInitialProps> => {
  const initialProps = await App.getInitialProps(context);
  const hostname = context.ctx?.req?.headers.host ?? '';

  return { ...initialProps, hostname };
};
