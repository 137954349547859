import { captureException } from '@sentry/nextjs';
import type { ParsedQuery } from 'query-string';
import qs from 'query-string';
import { v4 as uuid } from 'uuid';

export function getAnonUserId() {
  if (typeof window !== 'undefined') {
    const anonymousUuidInLocalStorage = window.localStorage.getItem('anonymousUuid');

    if (anonymousUuidInLocalStorage) {
      return anonymousUuidInLocalStorage;
    } else {
      const anonymousUuid = uuid();
      window.localStorage.setItem('anonymousUuid', anonymousUuid);

      return anonymousUuid;
    }
  } else {
    console.error('attempting to call getAnonUserId() on the server');
    return '';
  }
}

// https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
// Check if user-agent is on desktop or mobile
const mobileBrowser =
  /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i;

export const isMobileBrowser = (agents: string) => {
  const isMobileBrowser = mobileBrowser.test(agents);
  return isMobileBrowser;
};

export function extractDigits(value = '') {
  return value.replace(/\D/g, '');
}

/**
 * Returns the hostname from a given url string
 * @param url string
 * @returns string | null
 */
export const getHostnameFromURL = (url: string): string | null => {
  try {
    const domain = new URL(url);
    return domain.hostname;
  } catch (e) {
    captureException(e);
    return null;
  }
};

/**
 * Returns current path marketing query params
 * @returns
 */
export const parseMarketingParams = (queryString: string) => {
  const parsedParams: ParsedQuery<string> = qs.parse(queryString);
  return {
    utm_campaign: parsedParams.utm_campaign,
    utm_source: parsedParams.utm_source,
    utm_medium: parsedParams.utm_medium,
    utm_term: parsedParams.utm_term,
    utm_content: parsedParams.utm_content,
    gclid: parsedParams.gclid,
    msclkid: parsedParams.msclkid,
    cp_referral: parsedParams.cp_referral,
  };
};

/**
 * Returns the given url or path string with current query params preserved
 * @param urlPath string
 * @returns string
 */
export const getUrlPathWithPreservedQuery = (urlPath: string): string => {
  if (typeof window !== 'undefined') {
    const params = window.location.search;
    const marketingParams = qs.stringify(parseMarketingParams(params));
    return `${urlPath}${marketingParams ? `?${marketingParams}` : ''}`;
  } else {
    return urlPath;
  }
};
