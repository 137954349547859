import { useEffect } from 'react';
import type { ReactNode } from 'react';

import styled from 'styled-components';

import { invariant } from '../../utils';
import { Typography } from '../Typography/Typography';

/** If adjusted, please update documentation type. */
export interface SkipNavLink {
  contentId: string;
  display: ReactNode;
}

const StyledSkipNav = styled.div<{ hasLinks: boolean }>`
  margin: 0;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: ${({ theme }) => theme.space.s3};
  position: absolute;
  top: ${({ theme }) => theme.space.s10};
  left: 10px;
  min-width: 160px;
  min-height: 64px;
  padding-left: ${({ hasLinks, theme }) => (hasLinks ? theme.space.s5 : theme.space.s4)};
  padding-right: ${({ theme }) => theme.space.s4};
  padding-top: ${({ theme }) => theme.space.s3};
  padding-bottom: ${({ theme }) => theme.space.s3};
  border: 1px solid ${({ theme }) => theme.colors.capsuleGreen50};
  border-left: none;
  border-top-right-radius: ${({ theme }) => theme.radii.small};
  border-bottom-right-radius: ${({ theme }) => theme.radii.small};
  box-shadow: ${({ theme }) => theme.shadows.dp6};
  background-color: ${({ theme }) => theme.colors.capsuleGreen00};
  color: ${({ theme }) => theme.colors.capsuleGreen80};
  text-decoration: none;
  list-style: disc;
  transform: translateX(-200%);
  transition: transform ${({ theme }) => theme.animation.typical};

  ${({ theme }) => theme.mediaQueries.prefersReducedMotion} {
    transition: none;
  }

  &:focus-within {
    transform: translateX(0);
    outline: 2px solid ${({ theme }) => theme.colors.capsuleGreen60};
  }

  & a {
    outline: none;
  }
`;

const SkipNavListItem = styled.li`
  margin: 0;
  margin-left: ${({ theme }) => theme.space.s1};
  border-bottom: 2px solid ${({ theme }) => theme.colors.transparent};

  &:focus-within {
    border-color: ${({ theme }) => theme.colors.capsuleGreen60};
  }
`;

export const DEFAULT_SKIP_NAV_ID = 'main-content';

export interface SkipNavProps {
  /**
   * Exposed only in case there is a desire to style the component.
   */
  className?: string;

  /**
   * If left `undefined`, the SkipNav will only serve as a method to jump to "main content".
   * If defined, a navigation menu list will be rendered within the SkipNav allowing for other jump targets.
   *
   * `type SkipNavLink = { contentId: string; display: ReactNode }`
   */
  links?: SkipNavLink[];
}

/**
 * [What is a Skip Nav?](https://webaim.org/techniques/skipnav/)
 *
 * **tl;dr:** On most pages, users must skim through a many elements repeated across pages (like navigation links) before
 * ever arriving at the main content. This can be particularly difficult for users with some forms of motor disabilities.
 * Consider a user with limited arm movement that navigates a web page by using a stick in their mouth to press keyboard
 * keys. Requiring this example user to perform any action numerous times before reaching the main content poses an
 * unacceptable accessibility barrier.
 *
 * In Storybook, the `<SkipNav>` is forcily being made visible on render. In real use, it is only visible on focus;
 * however, for accessibility reasons, it is always _rendered_.
 */
export const SkipNav = ({ className, links }: SkipNavProps) => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') return;

    if (!links) {
      const mainContent = window.document.getElementById(DEFAULT_SKIP_NAV_ID);
      invariant(
        !!mainContent,
        `Your application is using a <SkipNav> component, but there exists no rendered element with the ID "main-content".`
      );
    } else {
      const missingContentIds = links
        .filter(link => !window.document.getElementById(link.contentId))
        .map(link => link.contentId)
        .join(', ');

      invariant(
        missingContentIds.length === 0,
        `Your application is using a <SkipNav> component, but there exists no rendered element with the following IDs: ${missingContentIds}.`
      );
    }
  }, [links]);

  const hasLinks: boolean = (links?.length ?? 0) > 0;

  return (
    <StyledSkipNav as={links ? 'ol' : undefined} className={className} hasLinks={hasLinks}>
      {links ? (
        links.map(({ display, contentId }) => (
          <SkipNavListItem key={contentId}>
            <Typography as="a" href={`#${contentId}`}>
              {display}
            </Typography>
          </SkipNavListItem>
        ))
      ) : (
        <Typography as="a" href={`#${DEFAULT_SKIP_NAV_ID}`}>
          Skip to Main Content
        </Typography>
      )}
    </StyledSkipNav>
  );
};
