import { forwardRef } from 'react';

import styled from 'styled-components';

import { ScreenReaderOnly } from '../ScreenReaderOnly/ScreenReaderOnly';

import { UnstyledButton } from './UnstyledButton';

import type { UnstyledButtonProps } from './UnstyledButton';

const StyledButton = styled(UnstyledButton).attrs({ type: 'button' })`
  background-color: ${({ theme }) => theme.colors.transparent};
`;

const StyledSVG = styled.svg.attrs({
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
  xmlns: 'http://www.w3.org/2000/svg',
  'aria-hidden': true,
})`
  display: block;
  fill: currentcolor;
`;

const XIcon = () => (
  <StyledSVG>
    <path d="M18.2929 4.29289C18.6834 3.90237 19.3166 3.90237 19.7071 4.29289C20.0976 4.68342 20.0976 5.31658 19.7071 5.70711L13.4142 12L19.7071 18.2929C20.0976 18.6834 20.0976 19.3166 19.7071 19.7071C19.3166 20.0976 18.6834 20.0976 18.2929 19.7071L12 13.4142L5.70711 19.7071C5.31658 20.0976 4.68342 20.0976 4.29289 19.7071C3.90237 19.3166 3.90237 18.6834 4.29289 18.2929L10.5858 12L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L12 10.5858L18.2929 4.29289Z" />
  </StyledSVG>
);

export interface XIconButtonProps extends Pick<UnstyledButtonProps, 'className' | 'id' | 'onClick'> {
  /**
   * This string is rendered in a `ScreenReaderOnly` component after the word "Dismiss".
   * Use it if you want to be more specific about WHAT is being dismissed after a user
   * presses this button.
   */
  helperText?: string;
}

/**
 * This is a `button` type button with icon and assistive, screenreader-only text indicating that it is used to
 * dismiss _something_. Often used with dialogs and other modals.
 */
export const XIconButton = forwardRef<HTMLButtonElement, XIconButtonProps>((props: XIconButtonProps, ref) => {
  return (
    <StyledButton {...props} ref={ref}>
      <ScreenReaderOnly>Dismiss{props.helperText ?? ''}</ScreenReaderOnly>

      <XIcon />
    </StyledButton>
  );
});
