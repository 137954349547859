import styled from 'styled-components';

import { Typography } from '../Typography/Typography';

const StyledBadge = styled(Typography).attrs({ as: 'span', variant: 'overline' })`
  display: inline-block;
  padding: 0px 8px;
  overflow: hidden;

  background: ${({ theme }) => theme.colors.capsuleGreen10};
  box-shadow: ${({ theme }) => theme.shadows.dp2};
  border-radius: ${({ theme }) => theme.radii.r300};

  color: ${({ theme }) => theme.colors.capsuleGreen60};
`;

interface BadgeProps {
  /**
   * The actual content.
   * We're using the `children` prop; however, it differs from default `children` prop in that it is required.
   * It also differs in that - currently - we only accept strings.
   */
  children: string;
  className?: string;
  [key: `data-${string}`]: any;
}

/**
 * There is currently no design system board for this component.
 */
export const Badge = (props: BadgeProps) => <StyledBadge {...props} />;
