import type { ReactNode } from 'react';

import { ScreenReaderOnly } from '../../ScreenReaderOnly/ScreenReaderOnly';

import { TextFieldDescription, TextFieldError } from './styles';

interface HelperTextProps {
  description: {
    identifier: string;
    isHidden: boolean;
    value?: string;
  };
  error: {
    identifier: string;
    value?: string | ReactNode;
  };
}

export const HelperText = ({ description, error }: HelperTextProps) => {
  if (!error.value && !description.value) return null;

  /**
   * The `title` prop is what enables browser-default tooltips on hover. The tooltip isn't accessible, but the
   * elements themselves are. So, this is just a nice QoL improvement for able-bodied, seeing-eye users.
   */
  const title = typeof error.value === 'string' && error.value.length > 60 ? error.value : undefined;

  if (!!error.value) {
    return (
      <>
        <TextFieldError id={error.identifier} title={title}>
          {error.value}
        </TextFieldError>

        {description.value && (
          <ScreenReaderOnly as={TextFieldDescription} id={description.identifier}>
            {description.value}
          </ScreenReaderOnly>
        )}
      </>
    );
  }

  if (description.isHidden) {
    return (
      <>
        <ScreenReaderOnly as={TextFieldDescription} id={description.identifier}>
          {description.value}
        </ScreenReaderOnly>
      </>
    );
  }

  return (
    <>
      <TextFieldDescription id={description.identifier} title={description.value}>
        {description.value}
      </TextFieldDescription>
    </>
  );
};
