export const CCPA_LINK = 'https://production-capsule-assets.s3.amazonaws.com/consumer-web/Capsule+CCPA+Notice+.pdf';

export const NAVBAR_STICKY_OFFSET = 585;

export const NAV_HEIGHT = 80;

export const IS_PRODUCTION = process.env.NEXT_PUBLIC_CAPSULE_ENV === 'production';

export const CAPSULE_ENVIRONMENT = process?.env?.NEXT_PUBLIC_CAPSULE_ENV || 'staging';

export const PATH_TO_NAME = {
  '': 'Default',
  '/': 'Home',
  '/questions': 'Questions',
};

export const CAPSULE_HELP_EMAIL = 'help@capsule.com'; // TODO consolidate instances of this

export const paths = {
  about: '/about',
  careers: '/careers',
  doctors: '/doctors',
  questions: '/questions',
  hipaa: '/hipaa',
  locations: '/locations',
  press: '/public/CapsulePressKit.pdf',
  privacy: '/privacy',
  termsAndConditions: '/terms',

  external: {
    blog: 'https://medium.com/capsule-cares',
    facebook: 'https://www.facebook.com/capsulecares',
    twitter: 'https://www.twitter.com/capsulecares',
    instagram: 'https://www.instagram.com/capsulecares',
    pressKit: '/public/CapsulePressKit.pdf',
    appStore: 'https://itunes.apple.com/app/apple-store/id1092333652',
    googlePlay: 'https://play.google.com/store/apps/details?id=com.capsule.capsule',
  },
};
