/**
 * This theme follows the system-ui spec
 * @see https://system-ui.com/theme
 */
export const theme = {
  animation: {
    function: {
      typical: 'ease',
    },
    timing: {
      typical: '250ms',
    },
    typical: `250ms ease`,
  },
  colors: {
    capsuleBlue30: 'hsla(213,24%,47%,1)',
    capsuleBlue50: 'hsla(211,76%,24%,1)',
    capsuleBlue60: 'hsla(214,58%,24%,1)',
    capsuleBlue90: 'hsla(215,53%,23%,1)',
    capsuleBlue90With80Alpha: 'hsla(215,53%,23%,0.8)',

    capsuleGray00: 'hsla(210,13%,97%,1)',
    capsuleGray10: 'hsla(210,8%,80%,1)',
    capsuleGray50: 'hsla(212,8%,47%,1)',

    capsuleGreen00: 'hsla(180,23%,95%,1)',
    capsuleGreen10: 'hsla(171,25%,90%,1)',
    capsuleGreen50: 'hsla(175,23%,67%,1)',
    capsuleGreen55: 'hsla(175,22%,55%,1)',
    capsuleGreen60: 'hsla(172,100%,26%,1)',
    capsuleGreen80: 'hsla(172,100%,23%,1)',

    capsuleRed: 'hsla(8,86%,56%,1)',
    capsuleRed10: 'hsla(8,97%,71%,1)',
    capsuleRed60: 'hsla(8,93%,47%,1)',
    capsuleRed70: 'hsla(8,94%,44%,1)',
    capsuleRed80: 'hsla(8,100%,37%,1)',

    capsuleYellow: 'hsla(50,100%,50%,1)',

    white: 'hsla(0,0%,100%,1)',
    transparent: 'hsla(0,0%,0%,0.001)',
  },
  fonts: {
    circular: 'Circular-Pro, Helvetica, Arial, sans-serif',
    mercury: 'MercuryTextG4, Georgia, Times New Roman, serif',
  },
  fontSizes: {
    displayXXXL: '8.4rem',
    displayXXL: '6.4rem',
    displayXL: '3.6rem',

    headerOne: '2.8rem',
    headerTwo: '2.4rem',
    headerThree: '2rem',
    headerFour: '1.6rem',

    body: '1.6rem',
    small: '1.2rem',
    overline: '1.2rem',

    callout: '1.5rem',
    calloutSmall: '1.3rem',
  },
  fontWeights: {
    book: 300,
    roman: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  iconSizes: {
    medium: '2rem',
    large: '2.4rem',
    extraLarge: '3.2rem',
  },
  letterSpacings: {
    normal: 'normal',
    one: '0.1rem',
  },
  lineHeights: {
    displayXXXL: '9.2rem',
    displayXXL: '7.2rem',
    displayXL: '4.4rem',

    headerOne: '3.2rem',
    headerTwo: '2.8rem',
    headerThree: '2.4rem',
    headerFour: '2rem',

    body: '2rem',
    small: '1.6rem',
    overline: '2rem',

    callout: '2rem',
    calloutSmall: '2rem',
  },
  mediaQueries: {
    prefersReducedMotion: `@media (prefers-reduced-motion)`,
    XXLarge: `@media screen and (min-width: 1280px)`,
    XXLargeDown: `@media screen and (max-width: 1279px)`,
    extraLarge: `@media screen and (min-width: 1200px)`,
    extraLargeDown: `@media screen and (max-width: 1199px)`,
    large: `@media screen and (min-width: 992px)`,
    largeDown: `@media screen and (max-width: 991px)`,
    medium: `@media screen and (min-width: 768px)`,
    mediumDown: `@media screen and (max-width: 767px)`,
    small: `@media screen and (min-width: 576px)`,
    smallDown: `@media screen and (max-width: 575px)`,
    hasHover: `@media (hover: hover)`,
  },
  radii: {
    /**
     * @deprecated Use r100
     */
    small: '0.3rem',

    /**
     * @deprecated Use r200
     */
    large: '1.2rem',

    circle: '50%',

    /**
     * Formerly known as "small"
     */
    r100: '0.3rem',

    /**
     * Formerly known as "large"
     */
    r200: '1.2rem',

    r300: '2.4rem',
  },
  shadows: {
    dp2: '0rem 0rem 0.1rem hsla(208,8%,47%,0.16), 0rem 0.2rem 0.8rem hsla(175,23%,67%,0.24)',
    dp6: '0rem 0rem 0.1rem hsla(208,8%,47%,0.16), 0rem 0.6rem 1.2rem hsla(175,23%,67%,0.32)',
    dp12: '0rem 0rem 0.1rem hsla(208,8%,47%,0.16), 0rem 1.2rem 2.4rem hsla(175,23%,67%,0.48)',
    dp24: '0rem 0rem 0.1rem hsla(208,8%,47%,0.16), 0rem 2.4rem 4.8rem hsla(175,23%,67%,0.6)',
  },
  space: {
    s1: '0.4rem',
    s2: '0.8rem',
    s3: '1.2rem',
    s4: '1.6rem',
    s5: '2.4rem',
    s6: '3.2rem',
    s7: '4.8rem',
    s8: '6.4rem',
    s9: '9.6rem',
    s10: '12.8rem',
  },
  textTransforms: {
    allCaps: 'uppercase',
  },
  /**
   * When documenting z-index usages, only do so for component library usages.
   */
  zIndex: {
    /**
     * Try this first if you're certain the element won't exist above or under another
     * element along the Z plane.
     */
    z0: 0,

    /**
     * The `TextField` component uses this z-index value on the input element to ensure that
     * the label is clickable.
     */
    z1: 1,

    /**
     * The `TextField` component uses this z-index value on it's adornments to ensure it
     * renders above its own, "raised" input element.
     */
    z2: 2,

    z3: 3,
  },
} as const;

export type ThemeType = typeof theme;
export type ThemeColorType = keyof ThemeType['colors'];
export type ThemeMediaQueryType = keyof ThemeType['mediaQueries'];
export type ThemeSpaceType = keyof ThemeType['space'];
