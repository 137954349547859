import { theme as designSystemTheme } from '@capsule/consumer-design-system';

import { keyframes } from 'styled-components';

export enum Breakpoint {
  XXLarge = '(min-width: 1280px)',
  XXLargeDown = '(max-width: 1279px)',
  extraLarge = '(min-width: 1200px)',
  extraLargeDown = '(max-width: 1199px)',
  large = '(min-width: 992px)',
  largeDown = '(max-width: 991px)',
  medium = '(min-width: 768px)',
  mediumDown = '(max-width: 767px)',
  small = '(min-width: 576px)',
  smallDown = '(max-width: 575px)',
}

/**
 * For properties that are not in the design system (e.g. colors.black)
 * we should go through and either integrate them in the design system
 * where approved, or replace them with something from the design system
 */
export const theme = {
  ...designSystemTheme,
  animationDelays: {
    0: '0ms',
    200: '200ms',
  },
  animationDurations: {
    200: '200ms',
    500: '500ms',
    750: '750ms',
    1000: '1000ms',
  },
  animations: {
    fadeOut: keyframes`
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    `,
    fadeIn: keyframes`
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    `,
  },
  animationSpeeds: {
    fast: '250ms',
  },
  borderWidths: {
    none: '0rem',
    small: '0.1rem',
    medium: '0.2rem',
  },
  /** Only use these with `useMediaQuery`. You likely wan't `mediaQueries` */
  breakpoints: Breakpoint,
  iconSizes: {
    ...designSystemTheme.iconSizes,
    sm: '0.8rem',
  },
  mediaQueries: {
    ...designSystemTheme.mediaQueries,
    smallWidthAndHeightDown: `@media screen ${Breakpoint.smallDown} and (max-height: 575px)`,
  },
  opacities: {
    none: 0,
    overlay: 0.8,
    full: 1,
  },
  radii: {
    ...designSystemTheme.radii,
    circle: '50%',
  },
  space: {
    ...designSystemTheme.space,

    loggedInHeaderNavHeight: '8rem',
    loggedInLayoutSideNavWidth: '26.8rem',

    /** @deprecated - just use 0, if you can */
    none: '0rem',

    // omg
    /** @deprecated - use "s"-prefixed values only */
    '-1.6': '-1.6rem',
    /** @deprecated - use "s"-prefixed values only */
    '0.1': '0.1rem',
    /** @deprecated - use "s"-prefixed values only */
    '0.2': '0.2rem',
    /** @deprecated - use "s"-prefixed values only */
    '0.24': '0.24rem',
    /** @deprecated - use "s"-prefixed values only */
    '0.3': '0.3rem',
    /** @deprecated - use "s"-prefixed values only */
    '0.5': '0.5rem',
    /** @deprecated - use "s"-prefixed values only */
    '0.6': '0.6rem',
    /** @deprecated - use "s"-prefixed values only */
    '0.7': '0.7rem',
    /** @deprecated - use "s"-prefixed values only */
    '0.9': '0.9rem',
    /** @deprecated - use "s"-prefixed values only */
    '1.0': '1.0rem',
    /** @deprecated - use "s"-prefixed values only */
    '1.25': '1.25rem',
    /** @deprecated - use "s"-prefixed values only */
    '1.3': '1.3rem',
    /** @deprecated - use "s"-prefixed values only */
    '1.35': '1.35rem',
    /** @deprecated - use "s"-prefixed values only */
    '1.4': '1.4rem',
    /** @deprecated - use "s"-prefixed values only */
    '1.5': '1.5rem',
    /** @deprecated - use "s"-prefixed values only */
    '1.8': '1.8rem',
    /** @deprecated - use "s"-prefixed values only */
    '2.0': '2.0rem',
    /** @deprecated - use "s"-prefixed values only */
    '2.086': '2.086rem',
    /** @deprecated - use "s"-prefixed values only */
    '2.1': '2.1rem',
    /** @deprecated - use "s"-prefixed values only */
    '2.365': '2.365rem',
    /** @deprecated - use "s"-prefixed values only */
    '2.5': '2.5rem',
    /** @deprecated - use "s"-prefixed values only */
    '2.6': '2.6rem',
    /** @deprecated - use "s"-prefixed values only */
    '2.7': '2.7rem',
    /** @deprecated - use "s"-prefixed values only */
    '2.8': '2.8rem',
    /** @deprecated - use "s"-prefixed values only */
    '2.9': '2.9rem',
    /** @deprecated - use "s"-prefixed values only */
    '3.0': '3.0rem',
    /** @deprecated - use "s"-prefixed values only */
    '3.3': '3.3rem',
    /** @deprecated - use "s"-prefixed values only */
    '3.314': '3.314rem',
    /** @deprecated - use "s"-prefixed values only */
    '3.4': '3.4rem',
    /** @deprecated - use "s"-prefixed values only */
    '3.5': '3.5rem',
    /** @deprecated - use "s"-prefixed values only */
    '3.6': '3.6rem',
    /** @deprecated - use "s"-prefixed values only */
    '3.7': '3.7rem',
    /** @deprecated - use "s"-prefixed values only */
    '3.8': '3.8rem',
    /** @deprecated - use "s"-prefixed values only */
    '4.0': '4.0rem',
    /** @deprecated - use "s"-prefixed values only */
    '4.1': '4.1rem',
    /** @deprecated - use "s"-prefixed values only */
    '4.2': '4.2rem',
    /** @deprecated - use "s"-prefixed values only */
    '4.4': '4.4rem',
    /** @deprecated - use "s"-prefixed values only */
    '4.5': '4.5rem',
    /** @deprecated - use "s"-prefixed values only */
    '4.6': '4.6rem',
    /** @deprecated - use "s"-prefixed values only */
    '5.0': '5.0rem',
    /** @deprecated - use "s"-prefixed values only */
    '5.2': '5.2rem',
    /** @deprecated - use "s"-prefixed values only */
    '5.6': '5.6rem',
    /** @deprecated - use "s"-prefixed values only */
    '6.0': '6.0rem',
    /** @deprecated - use "s"-prefixed values only */
    '6.2': '6.2rem',
    /** @deprecated - use "s"-prefixed values only */
    '6.6': '6.6rem',
    /** @deprecated - use "s"-prefixed values only */
    '7.0': '7.0rem',
    /** @deprecated - use "s"-prefixed values only */
    '7.2': '7.2rem',
    /** @deprecated - use "s"-prefixed values only */
    '7.8': '7.8rem',
    /** @deprecated - use "s"-prefixed values only */
    '8.0': '8.0rem',
    /** @deprecated - use "s"-prefixed values only */
    '8.7': '8.7rem',
    /** @deprecated - use "s"-prefixed values only */
    '10.0': '10rem',
    /** @deprecated - use "s"-prefixed values only */
    '11.5': '11.5rem',
    /** @deprecated - use "s"-prefixed values only */
    '12.2': '12.2rem',
    /** @deprecated - use "s"-prefixed values only */
    '12.9': '12.9rem',
    /** @deprecated - use "s"-prefixed values only */
    '14.4': '14.4rem',
    /** @deprecated - use "s"-prefixed values only */
    '15.0': '15rem',
    /** @deprecated - use "s"-prefixed values only */
    '24.0': '24rem',
    /** @deprecated - use "s"-prefixed values only */
    '24.9': '24.9rem',
    /** @deprecated - use "s"-prefixed values only */
    '29': '29rem',
    /** @deprecated - use "s"-prefixed values only */
    '30': '30rem',
    /** @deprecated - use "s"-prefixed values only */
    '32': '32rem',
    /** @deprecated - use "s"-prefixed values only */
    '33': '33rem',
    /** @deprecated - use "s"-prefixed values only */
    '38.4': '38.4rem',
    /** @deprecated - use "s"-prefixed values only */
    '40': '40rem',
    /** @deprecated - use "s"-prefixed values only */
    '44': '44rem',
    /** @deprecated - use "s"-prefixed values only */
    '60': '60rem',
    /** @deprecated - use "s"-prefixed values only */
    '62': '62rem',
    /** @deprecated - use "s"-prefixed values only */
    '75': '75rem',
  },
  shadows: { ...designSystemTheme.shadows },

  /**
   * @deprecated This property should not be used. Use the `space` property instead
   */
  dividerMargins: {
    leftIndent: '0.8rem 0 0.8rem 3.2rem',
    lg: '2.4rem 0',
  },
  /**
   * @deprecated Typography values are NOT meant to be used in isolation. Our Typography
   * component reflects the strict relative usages the tokens share depending on the variant
   * you're trying to render. Please use the Typography component instead. Extend it if you need.
   */
  fonts: { ...designSystemTheme.fonts },
  /**
   * @deprecated Typography values are NOT meant to be used in isolation. Our Typography
   * component reflects the strict relative usages the tokens share depending on the variant
   * you're trying to render. Please use the Typography component instead. Extend it if you need.
   */
  fontSizes: {
    ...designSystemTheme.fontSizes,
    quote: '4.8rem',
  },
  /**
   * @deprecated Typography values are NOT meant to be used in isolation. Our Typography
   * component reflects the strict relative usages the tokens share depending on the variant
   * you're trying to render. Please use the Typography component instead. Extend it if you need.
   */
  fontWeights: { ...designSystemTheme.fontWeights },
  /**
   * @deprecated Typography values are NOT meant to be used in isolation. Our Typography
   * component reflects the strict relative usages the tokens share depending on the variant
   * you're trying to render. Please use the Typography component instead. Extend it if you need.
   */
  letterSpacings: { ...designSystemTheme.letterSpacings },
  /**
   * @deprecated Typography values are NOT meant to be used in isolation. Our Typography
   * component reflects the strict relative usages the tokens share depending on the variant
   * you're trying to render. Please use the Typography component instead. Extend it if you need.
   */
  lineHeights: { ...designSystemTheme.lineHeights },
  /**
   * @deprecated Typography values are NOT meant to be used in isolation. Our Typography
   * component reflects the strict relative usages the tokens share depending on the variant
   * you're trying to render. Please use the Typography component instead. Extend it if you need.
   */
  textTransforms: { ...designSystemTheme.textTransforms },
  /**
   * @deprecated
   * It's very possible to avoid needing zIndex by ensuring the element you're rendering
   * is written last in the DOM. Potentially, as a "Portal" so it's guaranteed to be last.
   * The only time you should reach for this, is if you're fighting another zIndex and
   * _that_ zIndex is too difficult to portal or write lower in the DOM.
   * @see https://www.codegram.com/blog/stop-ab-using-z-index/
   */
  zIndices: {
    negative: '-1',
    topOne: '1',
    topTwo: '2',
    topThree: '3',
    overlay: '1000',
    overlayMore: '1001',
    modal: '2000',
    overlayEverything: '99999999',
    overlayEverythinger: '100000000',
  },
};

export type ThemeType = typeof theme;
export type ThemeColorType = keyof ThemeType['colors'];
export type ThemeSpaceType = keyof ThemeType['space'];
